let lang = {};

lang.Home = { EN: "Home", HE: "בית", AR: "الرئيسية" };

lang.ourproduct = { EN: "Our Product", HE: "השירות שלנו", AR: "منتجنا" };

lang.FeaturesOverviews = {
  EN: "Features & Overviews",
  HE: "מאפיינים",
  AR: "الخصائص ولمحات عامة",
};
lang.next = { EN: "Next", HE: "הבא", AR: "التالي" };
lang.prev = { EN: "Prev", HE: "קודם", AR: "السابق" };

lang.Price = { EN: "Price", HE: "מחיר", AR: "السعر" };

lang.Howitworks = { EN: "How it works", HE: "איך זה עובד?", AR: "كيف يتم ذلك" };

lang.Examples = { EN: "Examples", HE: "דוגמאות", AR: "أمثلة" };

lang.Reviews = { EN: "Reviews", HE: "חוות דעת והמלצות", AR: "مراجعات" };

lang.ContactUs = { EN: "Contact Us", HE: "צור קשר", AR: "اتصل بنا" };

lang.Signin = { EN: "Sign in", HE: "כניסה", AR: "تسجيل الدخول" };

lang.main = {
  EN: "Create your own customized digital card",
  HE: "צור את הכרטיס הדיגיטלי שלך בהתאמה אישית",
  AR: "أنشئ بطاقتك الرقمية المخصصة",
};

lang.main1 = {
  EN: "Simply created and shared online business card",
  HE: "צור בקלות ושתף כרטיס ביקור",
  AR: "بطاقة عمل إلكترونية يمكن إنشاءها ومشاركتها بسهولة",
};

lang.main2 = {
  EN: "Customizable designed card with all the details you want to share",
  HE: "עיצוב בהתאמה אישית עם כל המידע שתרצה לשתף",
  AR: "بطاقة مصممة حسب الطلب وتحتوي على كل المعلومات التي تريد مشاركتها",
};
lang.submitSurvey = {
  EN: "Submit Survey",
  HE: "הצבע ",
  AR: "اللون",
};
lang.main3 = {
  EN: "Increases your business exposure in online media",
  HE: "מגדיל את החשיפה של העסק שלך בערוצי התקשורת - הדיגיטלית",
  AR: "زيادة شهرة الأعمال التجارية بفضل وسائل التواصل الإلكترونية",
};

lang.main4 = {
  EN: "Share it easily with your clients and business partners",
  HE: "שתף את הכרטיס בקלות עם לקוחותיך ושותפיך העסקיים",
  AR: "سهولة المشاركة مع العملاء وشركاء العمل",
};

lang.mainbtn = {
  EN: "Create your own Easy Card",
  HE: "צור את ה Easy Card שלך",
  AR: "أنشئ بطاقة Easy Card الخاصة بك",
};

lang.FeaturesOverviews = {
  EN: "Features & Overviews",
  HE: "תכונות ומאפיינים",
  AR: "الخصائص ولمحات عامة",
};

lang.CREATEYOUROWNCUSTOMIZEDCARD = {
  EN: "CREATE YOUR OWN",
  HE: "צור את הכרטיס הדיגיטלי שלך בהתאמה אישית",
  AR: "أنشيء بطاقتك المخصصة",
};
lang.CREATEYOUROWNCUSTOMIZEDCARD1 = {
  EN: "CUSTOMIZED CARD",
  HE: "",
  AR: "",
};

lang.CONTACTDETAILSANDEASYCONNECTION = {
  EN: "CONTACT DETAILS AND",
  HE: "פרטי איש קשר ודרכי התקשרות קלה",
  AR: "معلومات الاتصال وتواصل سهل",
};

lang.CONTACTDETAILSANDEASYCONNECTION1 = {
  EN: "EASY CONNECTION",
  HE: "",
  AR: "",
};

lang.BUSINESSINFORMATION = {
  EN: "BUSINESS INFORMATION",
  HE: "מידע עסקי",
  AR: "المعلومات التجارية",
};

lang.CONTENTMANAGEMENTABILITY = {
  EN: "CONTENT MANAGEMENT",
  HE: "יכולת ניהול תכנים",
  AR: "القدرة على التحكم في المحتوى",
};

lang.CONTENTMANAGEMENTABILITY1 = {
  EN: "ABILITY",
  HE: "",
  AR: "",
};

lang.RECOMMENDATIONS = {
  EN: "RECOMMENDATIONS",
  HE: "המלצות",
  AR: "التوصيات",
};

lang.LEAVEAMESSAGEFORM = {
  EN: "LEAVE A MESSAGE FORM",
  HE: "טופס יצירת קשר",
  AR: "نموذج اترك رسالة",
};

lang.EASILYSHAREYOURCARD = {
  EN: "EASILY SHARE YOUR CARD",
  HE: "שיתוף כרטיסך בקלות",
  AR: "شارك بطاقتك بسهولة",
};

lang.CreateyourownEasyCard = {
  EN: "Create your own Easy Card",
  HE: "צור את ה Easy Card שלך",
  AR: "أنشئ بطاقة Easy Card الخاصة بك",
};

//HOW ITS WORK

lang.CreateyourownEasyCard = {
  EN: "",
  HE: "",
  AR: "",
};

lang.Howitworks = {
  EN: "How it works",
  HE: "איך זה עובד?",
  AR: "كيف يتم ذلك",
};

lang.ONLYFEW = {
  EN: "Only few steps to have your Easy Card ready in few minutes",
  HE: "רק כמה צעדים פשוטים ויהיה לך Easy Card משלך בכמה דקות",
  AR: "خطوات بسيطة تفصلك عن إنشاء بطاقة Easy Card الخاصة بك في بضع دقائق.",
};

lang.CreateYourOwnAccount = {
  EN: "Create Your Own",
  HE: "צור חשבון",
  AR: "أنشئ حسابك الخاص",
};

// lang.CreateYourOwnAccount1 = {
// 	EN: "Account",
// 	HE: "",
// 	AR: ""
// };

lang.PLEASEREGISTER = {
  EN: "Please register here to start using our service",
  HE: "הירשם כאן כדי להתחיל להשתמש בשירות שלנו",
  AR: "المرجو التسجيل من هنا للاستفادة من خدمتنا",
};

lang.PLEASEREGISTERBTN = {
  EN: "Get Registered",
  HE: "הירשם",
  AR: "تسجل",
};

lang.CreateyourownEasyCard = {
  EN: "Create your own",
  HE: "צור את ה Easy Card שלך",
  AR: "أنشئ بطاقة Easy Card الخاصة بك",
};

lang.CreateyourownEasyCardd = {
  EN: "Easy Card",
  HE: "",
  AR: "",
};
lang.CreateyourownEasyCard1 = {
  EN: "Select language",
  HE: "בחר שפה",
  AR: "أ. حدد اللغة",
};

lang.CreateyourownEasyCard2 = {
  EN: "Customize structure and sections",
  HE: "עצב את המבנה והחלקים השונים",
  AR: "قم بتخصيص الشكل والأقسام",
};

lang.CreateyourownEasyCard3 = {
  EN: "Customize buttons and background",
  HE: "בחר את הכפתורים ועצב את הרקע",
  AR: "قم بتخصيص الأزرار والخلفية",
};

lang.CreateyourownEasyCard4 = {
  EN: "Add all your content",
  HE: "הוסף את התכנים שלך",
  AR: "أضف المحتوى",
};

lang.PreviewYourNewEasyCard = {
  EN: "Preview Your New",
  HE: "צפה בכרטיס ה Easy Card  החדש שלך",
  AR: "قم بمعاينة بطاقة Easy Card الجديدة الخاصة بك",
};

lang.PreviewYourNewEasyCardd = {
  EN: "Easy Card",
  HE: "",
  AR: "",
};

lang.PreviewYourNewEasyCard1 = {
  EN:
    "Preview your Easy Card so you can either edit it to your satisfaction or publish it",
  HE: "צפה בכרטיס ה Easy Card כדי שתוכל לעדכן לשביעות רצונך או לפרסם אותו",
  AR:
    "يمكنك معاينة بطاقة Easy Card حتى تقرر ما إذا كنت تريد إجراء المزيد من التعديلات أو نشرها.",
};

lang.CompleteThePaymentProcess = {
  EN: "Complete The Payment",
  HE: "בצע תשלום",
  AR: "أكمل عملية الدفع",
};

lang.CompleteThePaymentProcesss = {
  EN: "Process",
  HE: "",
  AR: "أ",
};

lang.CompleteThePaymentProcess1 = {
  EN: "Select your preferred plan to activate your Easy Card",
  HE: "בחר את החבילה המועדפת עליך להפעיל את ה Easy Card שלך",
  AR: "حدد خطتك المفضلة لتفعيل بطاقة Easy Card الخاصة بك",
};

lang.ShareYourEasyCardWithYourClientsPartners = {
  EN: "Share Your Easy Card",
  HE: "שתף את כרטיס ה Easy Card שלך עם הלקוחות והשותפים שלך",
  AR: "شارك بطاقة Easy Card الخاصة بك مع العملاء والشركاء",
};

lang.ShareYourEasyCardWithYourClientsPartnerss = {
  EN: "With Your Clients & Partners",
  HE: "",
  AR: "",
};

lang.price = {
  EN: "Easy Card Comes With An Easy Price",
  HE: "לכרטיס ה Easy Card יש מחיר נוח לכל כיס",
  AR: "تأتي بطاقة Easy Card بأسعار في متناول الجميع",
};

lang.ex = {
  EN: "Examples",
  HE: "דוגמאות",
  AR: "أمثلة",
};

lang.ex1 = {
  EN: "Easy Cards examples",
  HE: "דוגמאות לכרטיסים דיגיטליים",
  AR: "أمثلة عن بطاقات Easy Card",
};

//Contact us
lang.contactus = {
  EN: "Contact Us",
  HE: "צור קשר",
  AR: "اتصل بنا",
};

lang.contactusfname = {
  EN: "First name",
  HE: "שם פרטי",
  AR: "الإسم الشخصي",
};

lang.contactuslname = {
  EN: "Last name",
  HE: "שם משפחה",
  AR: "الإسم العائلي",
};

lang.contactusEmail = {
  EN: "Email",
  HE: "דוא" + "ל",
  AR: "البريد الإلكتروني",
};

lang.contactusphonenumber = {
  EN: "Phone number",
  HE: "מספר טלפון",
  AR: "رقم الهاتف",
};

lang.Leaveusamessage = {
  EN: "Leave us a message",
  HE: "השאר הודעה",
  AR: "اترك لنا رسالة",
};

lang.Sendmessage = {
  EN: "Send message",
  HE: "שלח הודעה",
  AR: "أرسل الرسالة",
};

lang.Contactusvia = {
  EN: "Contact us via",
  HE: "צור איתנו קשר באמצעות:",
  AR: "اتصل بنا عبر",
};

//footer

lang.allrightsreserved = {
  EN: "All rights reserved",
  HE: "כל הזכויות שמורות",
  AR: "جميع الحقوق محفوظة",
};

lang.home = {
  EN: "Home",
  HE: "בית",
  AR: "الرئيسية",
};

lang.Productfeature = {
  EN: "Product features",
  HE: "מאפייני המוצר",
  AR: "خصائص المنتج",
};

lang.Price = {
  EN: "Price",
  HE: "מחיר",
  AR: "السعر",
};

lang.Howitworks = {
  EN: "How it works",
  HE: "איך זה עובד",
  AR: "كيف يتم ذلك",
};

lang.Examples = {
  EN: "Examples",
  HE: "דוגמאות",
  AR: "أمثلة",
};

lang.Reviews = {
  EN: "Reviews",
  HE: "חוות דעת",
  AR: "مراجعات",
};

lang.ContactUs = {
  EN: "Contact Us",
  HE: "צור קשר",
  AR: "اتصل بنا",
};

lang.Termsandonditions = {
  EN: "Terms and conditions",
  HE: "תנאי שימוש",
  AR: "الشروط والأحكام",
};

lang.privacypolicy = {
  EN: "Privacy policy",
  HE: "מדיניות פרטיות",
  AR: "سياسة الخصوصية",
};

lang.cancellationpolicy = {
  EN: "Cancellation policy",
  HE: "מדיניות ביטולים",
  AR: "سياسة الإلغاء",
};

lang.Businessaddress = {
  EN: "Business address",
  HE: "כתובת העסק",
  AR: "عنوان العمل",
};

lang.Businessaddress1 = {
  EN: "Yafa Yarkoni 14, Rosh Ha'ayin, Israel",
  HE: "יפה ירקוני 14, ראש העין, ישראל",
  AR: "Yafa Yarkoni 14, Rosh Ha'ayin, Israel",
};

lang.Link = {
  EN: "Link",
  HE: "קישור",
  AR: "الرابط",
};

lang.Signin = {
  EN: "Sign in",
  HE: "כניסה",
  AR: "تسجيل الدخول",
};

lang.SignUp = {
  EN: "Sign Up",
  HE: "הרשמה",
  AR: "تسجيل الاشتراك",
};

lang.SignUp = {
  EN: "Sign Up",
  HE: "הרשמה",
  AR: "تسجيل الاشتراك",
};

lang.emailtranslation = {
  EN: "Use the link to view",
  HE: "לצפייה בכרטיס הביקור הדיגיטלי ש",
  AR: "للتمعن في بطاقة العمل الالكترونيه ل",
};



lang.AboutUs = { EN: "About Us", HE: "אודותינו", AR: "معلومات عنا" };

lang.OurVision = { EN: "Our Vision", HE: "החזון ", AR: "رؤيتنا" };

lang.OurApplication = {
	EN: "Our Application",
	HE: "האפליקציה שלנו",
	AR: "تطبيقنا",
};

lang.SuccessStories = {
	EN: "Success Stories",
	HE: "סיפורי הצלחה",
	AR: "قصص النجاح",
};

lang.ActivityHour = {
	EN: "Activity Hour",
	HE: "שעות פעילות",
	AR: "ساعة النشاط",
};

lang.ArticleTitle = {
	EN: "Articles", HE: "מאמרים", AR: "المقالات" };


lang.leaveamessage = {
	EN: "Leave A Message",
	HE: "השאר הודעה",
	AR: "اترك رسالة",
};

lang.contactformemailvalid = {
	EN: "Enter a valid Email",
	HE: 'אנא הכנס דוא"ל תקין',
	AR: "أدخل بريد إلكتروني صالح",
};

lang.contactformfname = {
	EN: "please enter first name",
	HE: "אנא הכנס שם",
	AR: "الرجاء إدخال الإسم",
};

lang.contactformlname = {
	EN: "please enter last name",
	HE: "TBD",
	AR: "TBD",
};

lang.contactformmessage = {
	EN: "please enter message",
	HE: "אנא הכנס הודעה",
	AR: "الرجاء إدخال الرسالة",
};

lang.contactformnumber = {
	EN: "please enter a phone number",
	HE: "אנא הכנס מספר תקין",
	AR: "أدخل رقما صالحا",
};

lang.contactformnumberv = {
	EN: "Enter a valid number",
	HE: "אנא הכנס מספר תקין",
	AR: "أدخل رقما صالحا",
};


lang.Recommendations = { EN: "Recommendations", HE: "המלצות", AR: "التوصيات" };

lang.Employees = { EN: "Employees", HE: "צוות", AR: "الموظفين" };


lang.Background = {
	EN: "Background",
	HE: "רקע",
	AR: "الخلفية",
};

lang.emailtitle = {
	EN: "Someone left you a message",
	HE: "מישהו השאיר לך הודעה",
	AR: "شخص ما ترك لكم رسالة",
};

lang.Survey={
  EN: "Survey",
	HE: "סקר",
	AR: "استطلاع",
}

lang.Enter_Survey_Title ={
  EN: "Enter Survey Title ",
	HE: "הזן כותרת לסקר",
	AR: "أدخل عنوان الاستطلاع",
}

lang.Enter_Option ={
  EN: "Enter Option",
	HE: "הזן אפשרות",
	AR: "أدخل الخيار",
}
lang.Please_enter_survey_title  ={
  EN: "Please enter survey title ",
	HE: "אנא הזן כותרת לסקר",
	AR: "الرجاء إدخال عنوان الاستطلاع",
}
lang.please_use_complete ={
  EN: "please use complete url i.e....",
	HE: "אנא השתמש בקישור מלא למשל....",
	AR: "الرجاء استخدام عنوان URL الكامل، أي...",
}

lang.Generate_QR_Code ={
  EN: "Generate QR Code.",
	HE: "צור קוד",
	AR: "توليد رمز الاستجابة السريعة",
}

lang.Scan_QR_Code = {
  EN: "Scan QR Code",
  HE: "סרוק קוד QR",
  AR: "مسح رمز الاستجابة السريعة"
  // HE: "לסרוק קוד QR",
  // AR: "مسح رمز الاستجابة السريعة",
};


lang.QR_code_url ={
  EN: "QR code will be created per your card URL and will appear in your card",
	HE:"קוד QR  ייווצר לפי הקישור לכרטיסך ויופיע בכרטיסך",
	AR: "سيتم إنشاء رمز الاستجابة السريعة وفقا لعنوان URL الخاص ببطاقتك وسيظهر في بطاقتك",
}

lang.Enter_coupone_code ={
  EN: "Enter coupone code",
	HE: "הכנס קוד קופון",
	AR: "أدخل رمز القسيمة",

}

lang.Apply ={
  EN: "Apply",
	HE: "בדוק תקינות",
	AR: "استعمل",
}

lang.Your_price_is ={
  EN: "Your price is:",
	HE: "המחיר שלך הוא",
	AR: "سعرك هو:",
}

lang.Your_coupone_is ={
  EN: "Your coupone is:",
	HE: "הקופון שלך הוא",
	AR: "قسيمتك هي:"
}

lang.Continue ={
  EN: "Continue",
	HE: "המשך",
	AR: "المواصلة"
}

lang.Full_Name ={
  EN: "Full Name",
	HE: "שם מלא",
	AR: "الإسم الكامل"
}

lang.Company ={
  EN: "Company",
	HE: "חברה",
	AR: "الشركة"
}

lang.Email_address ={
  EN: "Email address",
	HE: "דואל",
	AR: "عنوان البريد الإلكتروني"
}

lang.Mobile_number={
  EN: "Mobile number:",
	HE: "מספר טלפון נייד",
	AR: "رقم الهاتف المحمول"
}

lang.Tax={
  EN: "Tax:",
	HE: "מעמ",
	AR: "الضريبة"
}

lang.Total={
  EN: "Total:",
	HE: "סך הכל",
	AR: "المجموع:"
}

lang.Complete_purchase={
  EN: "Total:",
	HE: "השלם רכישה",
	AR: "إكمال عملية الشراء"
}

lang.Fill_marked_fields={
  EN: "Fill in the marked fields",
	HE: "מלא את השדות המסומנים",
	AR: "املأ الخانات البارزة"
}

lang.Votes={
  EN: "Votes",
	HE: "הצבעות",
	AR: "التصويتات"
}

lang.Options={
  EN: "Options",
	HE: "אפשרויות",
	AR: "الخيارات"
}

lang.Card_Survey_Graph={
  EN: "Card Survey Graph",
	HE: "גרף הסקר בכרטיס",
	AR: "رسم بياني لاستطلاع البطاقة"
}

lang.No_Vote_made_against_this_survey={
  EN: "No Vote made against this survey",
	HE: "לא בוצעו הצבעות לסקר הזה",
	AR: "لم يتم إجراء أي تصويت ضد هذا الاستطلاع"
}

lang.Confirmation_message ={
  EN:"Congratulations!!!  (title - centered bold) Your new Easy Card is activated and ready to use. Now you could start sharing your card with customers and colleagues. Note: if you hold an Android device you could use our auto reply application that will help you spread your card!! Go to ctrlreply.com and download the Controlled Reply application today! Use the following coupon WelPromCo!@123Q and enjoy the best price! Got it (button - centered)",
	HE: "ברכות!!! כרטיס ה EASY CARD שלך הופעל ומוכן לשימוש. עכשיו תוכל להתחיל לשתף את הכרטיס שלך עם לקוחות וקולגות.שים לב, אם ברשותך מכשיר אנדרואיד, תוכל להשתמש באפליקצית המענה האוטומטי שלנו שתעזור לך להפיץ את כרטיסך!!בקר ב ctrlreply.com  והורד את אפליקציית Controlled Reply  היום.השתמש בקופון הבא: WelPromCo!@123Q ותוכל להנות כבר היום ממחיר מוזל!!המשך",
	AR: "مبروك!!!قد تم تنشيط بطاقةEasy Cardجديدة الخاصة بك وهي الآنالجاهزة للاستخدامجاهزة للاستخدامطاقتك مع العملاء والزملاء.احظة: إذا كان لديك جهاز يعململنظام أندرويد، يمكنك استخدامتطبيق الرد التلقائي الذي سيزيدمن وتيرة انتشار بطاقتك!! اذهب إل ctrlreply.com وقم زيل تطبيق الرد بتنControlled Reply اليوم! ستخدم القسيمة التاليةWelPromCo!@123Q واستمتع بأفضل الأسعار!حسن"
}
export default [lang];
