import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as actionCreater from "../../Store/Actions/CardActions";
import axios from "axios";
import $ from "jquery";
import Carousel from "nuka-carousel";
import { isMobile, isIOS } from "react-device-detect";
import alertify from "alertifyjs";
import APIModel from "../../Models/APIModel";
import T from "../../Helpers/Translations";
import QRCode from "qrcode.react";

class LogedInUserCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      card: {},
      user: {},
      startTime: [],
      endTime: [],
      slug: "",
      process: true,
      noCard: false,
      message_name: "",
      message_phone: "",
      message_email: "",
      message_message: "",
      host: APIModel.HOST,
      localStorageUserId: "",
      paramsUserId: "",
      pageurl: "",
      login: true,
      browsercheck: "",
      browser: true,
      survey_answer: "",
      survey_check: "",
      survey_text: "",
      data: "",
      bar_data: "",
    };
    this.get = this.get.bind(this);
    this.onChange = this.onChange.bind(this);
    // this.fnocard = this.fnocard.bind(this);
    this.checkforlogin = this.checkforlogin.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
  }

  componentWillMount() {
    let slug = this.props.match.params.slug;
    this.setState({ slug: slug });
    let paramsUserIdstring = this.props.match.params.userid;
    let paramsUserId = parseInt(paramsUserIdstring);
    this.setState({ paramsUserId: paramsUserId });
    let easycardUser = localStorage.getItem("easycardUser");
    this.Getuserbrowserid(paramsUserId);
    this.checksurveyuser(slug);
    this.getsurveydata(slug);
    // if(easycardUser === "" || easycardUser === null || easycardUser === undefined){
    //     alert("please login to view card");
    // }else{
    //let localStorageUserId = this.readCookie('userid');
    //this.setState({localStorageUserId:localStorageUserId});
    //}\
    let pageURL = window.location.href;

    this.setState({ pageurl: pageURL });
  }

  readCookie = (name) => {
    var nameEQ = encodeURIComponent(name) + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0)
        return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
    return null;
  };

  // fnocard() {
  //   if (this.state.login == false) {
  //     return (
  //       <div className="container">
  //         <div className="row" style={{ "margin-top": "6%" }}>
  //           <div className="col-md-12 text-center">
  //             <img src="/assets/images/404.png" alt="" />
  //           </div>
  //         </div>
  //         <div className="row">
  //           <div
  //             className="col-md-12 text-center"
  //             style={{
  //               "margin-top": "20px",
  //               "font-weight": "bolder",
  //               color: "#B55D15"
  //             }}
  //           >
  //             <h1>create your own easy card now!</h1>
  //           </div>
  //         </div>
  //       </div>
  //     );
  //   } else {
  //     return <div></div>;
  //   }
  // }

  componentDidMount() {
    alertify.set("notifier", "position", "top-right");
    // let easycardUser  = localStorage.getItem('easycardUser');

    // if(easycardUser === "" || easycardUser === null || easycardUser === undefined){

    //     alert("please login to view card");

    // }else{

    //     let localStorageUserId = this.state.localStorageUserId;
    //
    //     let paramsUserId = this.state.paramsUserId;
    //
    //     if(localStorageUserId == paramsUserId){

    let slug = this.props.match.params.slug;
    this.setState({ slug: slug });
    let paramsUserIdstring = this.props.match.params.userid;
    let paramsUserId = parseInt(paramsUserIdstring);
    this.setState({ paramsUserId: paramsUserId });
    this.get(slug, paramsUserId);

    // }else{

    //     alert("You are not Authorized to view this Card");

    // }
    //}
  }

  componentDidUpdate() {
    this.mobileScripts();
  }

  componentWillReceiveProps(nextProps) {
    let str = nextProps.match.url;
    let newstr = str.replace("/", "");
    var res = str.split("/");
    let paramsUserIdstring = res[2];
    let paramsUserId = parseInt(paramsUserIdstring);
    let slug = res[4];
    this.get(slug, paramsUserId);
  }

  errorHandler = (error) => {
    const { response } = error;

    let message;
    if (response) {
      message = response.data.message;
    } else {
      message = error.toString();
    }
    // alertify.error(message);
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onValueChange = (e) => {
    this.setState({
      survey_answer: e.target.value,
    });
  };

  getsurveydata(slug) {
    let params = {
      card_slug: slug,
    };

    return axios
      .post(this.state.host + "survey_check_data", params, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        if (response.data.message == "1") {
          var result = [];
          var result1 = [];
          console.log("survey_data");
          var keys = Object.keys(response.data.data.graph_data);
          keys.forEach(function (key) {
            result.push(response.data.data.graph_data[key]);
          });
          var keys1 = Object.keys(response.data.data.new_chart);
          keys1.forEach(function (key) {
            result1.push(response.data.data.new_chart[key]);
          });
          this.setState({
            survey_text: Object.values(response.data.data.keywords),
          });
          this.setState({ data: result });
          this.setState({ bar_data: result1 });
        }
        console.log("graph dATA");
        console.log(this.state.survey_text);
        console.log(this.state.data);
      });
  }
  checksurveyuser(slug) {
    let params = {
      userbrowserid: localStorage.getItem("Browser_id"),
      card_slug: slug,
    };

    return axios
      .post(this.state.host + "survey_check_user", params, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        if (response.data.message === "1") {
          this.setState({ survey_check: 1 });
        } else {
          this.setState({ survey_check: 2 });
        }
      });
  }
  submit_survey = (e) => {
    let params = {
      survey_option: this.state.survey_answer,
      user_browser_id: localStorage.getItem("Browser_id"),
      card_id: this.state.card.id,
      slug: this.props.match.params.slug,
    };
    // console.log(params); return;
    if (params.survey_option == "") {
      alertify.error("Please Select one option");
      return;
    }
    return axios
      .post(this.state.host + "survey_add", params, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log(response.data.message);
        window.location.reload();
      });
  };
  getImages = (key, i) => {
    let self = this;

    this.setState({
      image_process: true,
    });

    if (key === "carousel") {
      key = "images";
      if (i) {
        i.forEach(function (ii) {
          axios
            .get(ii, {
              responseType: "arraybuffer",
              headers: {
                "Content-Type":
                  "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8",
                Accept:
                  "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8",
              },
            })
            .then((res) => {
              let image = btoa(
                new Uint8Array(res.data).reduce(
                  (data, byte) => data + String.fromCharCode(byte),
                  ""
                )
              );
              const posts = `data:${res.headers[
                "content-type"
              ].toLowerCase()};base64,${image}`;
              // self.handleDefaultImages(posts);
            });
        });
      }
    } else {
      axios
        .get(i, {
          responseType: "arraybuffer",
          headers: {
            "Content-Type":
              "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8",
            Accept:
              "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8",
          },
        })
        .then((res) => {
          let image = btoa(
            new Uint8Array(res.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          const posts = `data:${res.headers[
            "content-type"
          ].toLowerCase()};base64,${image}`;
          self.setState({
            [`${key}`]: posts,
          });
        })
        .finally(function () {
          self.setState({
            image_process: 0,
          });
        });
    }
  };

  Getuserbrowserid = (e) => {
    return axios
      .get(this.state.host + "getbid/" + e, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        let z = localStorage.getItem("Browser_id");
        if (response.data.data.broswer_id === z) {
          this.setState({ browser: true });
        } else {
          this.setState({ browser: false });
        }
      });
  };

  checkforlogin(res) {
    res.data.error
      ? this.setState({ login: false })
      : this.setState({ login: true });
  }

  get = (slugb, userId) => {
    let { get, errorHandler } = this.props;

    let slugs = { slug: slugb, user_id: userId };
    console.log(slugs);
    get(slugs)
      .then((res) => {
        this.checkforlogin(res);

        if (res.data.message == "No Card") {
          console.log("condition true here");
          this.setState({
            noCard: true,
          });
        } else if (res.data.message === undefined) {
          let card = res.data.card[0];

          // console.log(card);
          // return;
          let m = JSON.parse(card.permission);
          let c = 0;
          m.phone ? c++ : void 0;
          m.alt_mobile ? c++ : void 0;
          m.fax ? c++ : void 0;
          m.office_number ? c++ : void 0;
          m.whatsapp ? c++ : void 0;
          m.website ? c++ : void 0;
          m.waze ? c++ : void 0;
          m.facebook ? c++ : void 0;
          m.twitter ? c++ : void 0;
          m.instagram ? c++ : void 0;
          m.email ? c++ : void 0;
          m.google_plus ? c++ : void 0;
          m.linkdin ? c++ : void 0;
          m.youtube ? c++ : void 0;
          m.tiktok ? c++ : void 0;

          this.setState({
            card: card,
            id: card.id,
            card_title: card.card_title,
            card_video: card.card_video,
            card_url: card.slug,
            about_us: JSON.parse(card.about_us),
            our_vision: JSON.parse(card.vision),
            profile_image: JSON.parse(card.images).profile_image,
            business_image: JSON.parse(card.images).business_image,
            background_image: JSON.parse(card.images).background_image,
            images: JSON.parse(card.images).carousel,
            our_application: JSON.parse(card.applications),
            card_url_typo: true,
            card_links: JSON.parse(card.card_links),
            our_stories: JSON.parse(card.stories),
            activity_hour: JSON.parse(card.activity_hour),
            articles: JSON.parse(card.articles),
            employees: JSON.parse(card.employees),
            recommendations: JSON.parse(card.recommendations),
            slugCan: "",
            checked: JSON.parse(card.permission),
            colors: JSON.parse(card._colors),
            leave_a_message_label: JSON.parse(card._info).leave_a_message_label,
            leave_a_message_email: JSON.parse(card._info).leave_a_message,
            name: JSON.parse(card._info).name,
            vcard: JSON.parse(card._info).vcard,
            email: JSON.parse(card._info).email,
            position: JSON.parse(card._info).position,
            specialization: JSON.parse(card._info).specialization,
            field_title:
              card._info.length !== 0
                ? Object.keys(JSON.parse(card._info).field)[0]
                : "About Us",
            field_value:
              card._info.length !== 0
                ? JSON.parse(card._info).field[
                    Object.keys(JSON.parse(card._info).field)
                  ]
                : "",
            phone: JSON.parse(card._contacts).phone,
            alt_mobile: JSON.parse(card._contacts).alt_mobile,
            fax: JSON.parse(card._contacts).fax,
            office_number: JSON.parse(card._contacts).office_number,
            whatsapp: JSON.parse(card._contacts).whatsapp,
            language: card.language,
            language_val: card.language,
            website: JSON.parse(card._socials).website,
            waze: JSON.parse(card._socials).waze,
            facebook: JSON.parse(card._socials).facebook,
            instagram: JSON.parse(card._socials).instagram,
            twitter: JSON.parse(card._socials).twitter,
            linkdin: JSON.parse(card._socials).linkdin,
            google_plus: JSON.parse(card._socials).google_plus,
            youtube: JSON.parse(card._socials).youtube,
            tiktok: JSON.parse(card._socials).tiktok,
            process: false,
            button_current: c,
            noCard: false,
            survey: JSON.parse(card.survey),
          });

          // console.log(this.state.survey);
          // return;
        }
      })
      .catch(this.errorHandler)
      .finally();
  };

  validateEmail = (email) => {
    const pattern =
      /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    const result = pattern.test(email);
    return result;
  };
  validatenumber(num) {
    const re = /^[0-9]+$/;
    return re.test(num);
  }

  leaveamessage = () => {
    let lang = $("html").attr("lang").toUpperCase();
    let t = T[0];
    if (this.state) {
      if (this.state.message_name === "" || this.state.message_name === null) {
        alertify.error("please enter name");
        return false;
      }

      if (
        this.state.message_message === "" ||
        this.state.message_message === null
      ) {
        alertify.error(t.contactformmessage[lang]);
        return false;
      }

      if (this.validateEmail(this.state.message_email)) {
      } else {
        alertify.error(t.contactformemailvalid[lang]);
        return false;
      }

      if (this.validatenumber(this.state.message_phone)) {
      } else {
        alertify.error(t.contactformnumberv[lang]);
        return false;
      }

      let title = t.emailtitle[lang];

      let params = {
        send_to: this.state.leave_a_message_email,
        from_name: this.state.message_name,
        from_email: this.state.message_email,
        from_number: this.state.message_phone,
        from_message: this.state.message_message,
        from_title: title,
      };

      return axios
        .post(this.state.host + "admin/message/send", params, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + this.state.token,
          },
        })
        .then((response) => {
          alertify.success(response.data.message);
        })
        .catch(this.globalErrorHandler)
        .finally();
    }
  };

  globalErrorHandler = (error) => {
    const { response } = error;
    let message;
    if (response) {
      message = response.data.message;
    } else {
      message = error.toString();
    }
  };

  desktopScripts() {
    $("html, body").css({
      overflow: "hidden",
      height: "100%",
    });
    $("body").addClass("bgmain");
  }

  renderVideo() {
    if (this.state.card_video == "" || this.state.card_video == null) {
      console.log(" no video");
    } else {
      let checkVideo = this.state.card_video;
      var newstrs = checkVideo.replace("watch?v=", "embed/");
      console.log("vide0" + newstrs);
    }

    const video = (
      <Fragment>
        {this.state.checked.card_video ? (
          <iframe
            width="100%"
            height="350"
            scrolling="no"
            src={"https://www.youtube.com/embed/" + newstrs}
            frameBorder="0"
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        ) : (
          ""
        )}
      </Fragment>
    );
    return video;
  }

  mobileScripts() {
    if (this.state.colors) {
      if (this.state.button_current) {
        if (this.state.button_current === 6) {
          $("#custom-card-wrapper .col-3").css("flex", "0 0 26%");
          $("#custom-card-wrapper .col-3").css("max-width", "unset");
        } else if (this.state.button_current === 5) {
          $("#custom-card-wrapper .col-3").css("flex", "0 0 26%");
          $("#custom-card-wrapper .col-3").css("max-width", "unset");
        }
      }

      $("#custom-card-wrapper #about-us-container").css(
        "background-color",
        this.state.colors.contact_background_color
      );
      $("#custom-card-wrapper #about-us-title").css(
        "color",
        this.state.colors.contact_forground_color
      );
      $("#custom-card-wrapper #about-us-title").css(
        "border-bottom",
        "2px solid " + this.state.colors.contact_forground_color
      );
      $("#custom-card-wrapper #about-us-content p").css(
        "color",
        this.state.colors.contact_forground_color
      );
      $("#custom-card-wrapper #reach-us-button").css(
        "background-color",
        this.state.colors.contact_forground_color
      );
      $("#custom-card-wrapper #add-contact").css(
        "background-color",
        this.state.colors.content_button_background_color
      );
      $("#custom-card-wrapper #add-contact").css(
        "color",
        this.state.colors.contact_forground_color
      );
      $("#custom-card-wrapper #add-contact .svg-icon").css(
        "fill",
        this.state.colors.contact_forground_color
      );
      $("#custom-card-wrapper #share-link-background").css(
        "background-color",
        this.state.colors.default_background_color
      );
      $("#custom-card-wrapper #share-link-background").css(
        "background-color",
        this.state.colors.default_background_color
      );
      $("#custom-card-wrapper .logo-img").css(
        "background-color",
        this.state.colors.contact_background_color
      );
      $("#custom-card-wrapper .about-us-section-row").css(
        "background-color",
        this.state.colors.contact_background_color
      );
      $("#custom-card-wrapper #name1, #name2").css(
        "color",
        this.state.colors.contact_forground_color
      );
      $("#custom-card-wrapper #designations").css(
        "color",
        this.state.colors.contact_forground_color
      );
      $("#custom-card-wrapper #reach-us-icons").css(
        "background-color",
        this.state.colors.buttons_background_color
      );
      $("#custom-card-wrapper .icons-div a").css(
        "background-color",
        this.state.colors.buttons_icon_background_color
      );
      $("#custom-card-wrapper .icons-div a").css(
        "border-color",
        this.state.colors.buttons_icon_fill_color
      );
      $("#custom-card-wrapper .icons-div a .svg-icon").css(
        "fill",
        this.state.colors.buttons_icon_fill_color
      );
      $("#custom-card-wrapper .icons-div p").css(
        "color",
        this.state.colors.buttons_text_color
      );
      $("#custom-card-wrapper #dropdown-background").css(
        "background-color",
        this.state.colors.content_background_color
      );
      $("#custom-card-wrapper .resume-wrap").css(
        "background-color",
        this.state.colors.content_button_background_color
      );
      $("#custom-card-wrapper .date button").css(
        "background",
        this.state.colors.content_button_background_color
      );
      $("#custom-card-wrapper .info-prop-p").css(
        "color",
        this.state.colors.content_text_color
      );
      $("#custom-card-wrapper .resume-wrap h6").css(
        "color",
        this.state.colors.content_text_color
      );
      $("#custom-card-wrapper .resume-wrap").css(
        "border-color",
        this.state.colors.content_button_background_color
      );
    }
  }

  renderMobileCard() {
    var options1 = {
      scales: {
        xAxes: [
          {
            ticks: {
              beginAtZero: true,
              min: 0,
            },
          },
        ],
      },
    };
    const data = {
      labels: this.state.survey_text,
      datasets: [
        {
          label: "# of Votes",
          data: this.state.bar_data,
          backgroundColor: [
            "rgb(255, 99, 71)",
            "rgb(255, 165, 0)",
            "rgb(255, 215, 0)",
            "rgb(0, 255, 255)",
            "rgb(0, 0, 128)",
            "rgb(128, 0, 128)",
            "rgb(255, 0, 0)",
            "rgb(0, 0, 255)",
            "rgb(255, 192, 203)",
            "rgb(165, 42, 42)",
          ],
        },
      ],
    };
    let lang = this.state.language;
    if (lang === "HE" || lang === "AR") {
      $("html").attr("dir", "rtl");
      $("html").attr("lang", lang);
    } else {
      $("html").attr("dir", "ltr");
      $("html").attr("lang", lang);
    }
    let t = T[0];

    let counter_button = 0;
    return (
      <div id="custom-card-wrapper">
        <link
          href="/assets/css/iphonefix.css"
          rel="stylesheet"
          type="text/css"
          media="all"
        />
        {/* <Helmet>
        <title>{this.state.card.card_title}</title>
        <link rel="canonical" href="https://dev.easy-card.co/" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Easy Card" />
        <meta property="og:description" content="Easy Card" />
        <meta property="og:url" content="https://dev.easy-card.co/" />
        <meta property="og:site_name" content="Easy Card" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content="Easy Card" />
        <meta name="twitter:title" content="Easy Card" />
        <meta
          property="og:image"
          content="https://dev.easy-card.co//assets/images/easy-card-preview.png"
        />
        <meta property="og:image:width" content="450" />
        <meta property="og:image:height" content="298" />
        <meta
          name="twitter:image"
          content="https://dev.easy-card.co//assets/images/easy-card-preview.png"
        />
      </Helmet> */}
        <div id="header-bg">
          <div className="container">
            <div className="row">
              {this.state.checked.background_image &&
              (this.state.background_image !== undefined ||
                this.state.background_image !== "") ? (
                <img
                  src={this.state.background_image}
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                />
              ) : (
                <img
                  src="/assets/images/business/1.jpg"
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                />
              )}
            </div>
          </div>
        </div>

        <div id="divider"></div>

        {this.state.checked.business_image ||
        this.state.business_image ||
        this.state.checked.profile_image ||
        this.state.profile_image ||
        this.state.name ||
        this.state.position ||
        this.state.specialization ? (
          <div className="container" id="share-link-background">
            <div className="row">
              {this.state.checked.business_image &&
              this.state.business_image ? (
                <div style={{ display: "contents" }}>
                  <div className="logo-img img-properties d-flex align-items-center justify-content-center checking">
                    <img
                      src={this.state.business_image}
                      className="img-responsive"
                    />
                  </div>
                  <br />
                </div>
              ) : (
                ""
              )}
              <div className="container about-us-section">
                <div className="row about-us-section-row">
                  {this.state.checked.profile_image &&
                  this.state.profile_image ? (
                    <div>
                      <div className="col-xs-5 about-us-section-row-img">
                        <img
                          src={this.state.profile_image}
                          className="img-responsive person-img"
                          height="150"
                        />
                      </div>
                      <div className="col-xs-6 my-auto name-title-div">
                        <center>
                          <p id="name1">{this.state.name}</p>
                          <p id="name2"></p>
                          <p id="designations">
                            {this.state.position}
                            <br />
                            {this.state.specialization}
                          </p>
                        </center>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="col-xs-12 m-auto"
                      style={{ paddingTop: "2em" }}
                    >
                      <center>
                        <p id="name1">{this.state.name}</p>
                        <p id="name2"></p>
                        <p id="designations">
                          {this.state.position}
                          <br />
                          {this.state.specialization}
                        </p>
                      </center>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}

        <div id="reach-us-icons">
          <div className="container">
            <div className="row">
              {counter_button < 8 &&
              this.state.checked.phone === true &&
              this.state.phone !== undefined &&
              this.state.phone !== null ? (
                <div className="icons-div col-3" counter={counter_button++}>
                  <a href={"tel:" + this.state.phone}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 4.5 4.5"
                      className="svg-icon mobile-icon"
                      aria-hidden="true"
                    >
                      <path
                        id="XMLID_3_"
                        class="st0"
                        d="M3.2,0H1.3C1.1,0,1,0.1,1,0.3v3.8c0,0.2,0.1,0.3,0.3,0.3h1.9c0.2,0,0.3-0.1,0.3-0.3V0.3
			C3.5,0.1,3.4,0,3.2,0z M1.8,0.2h0.9c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1H1.8C1.7,0.4,1.7,0.3,1.8,0.2C1.7,0.2,1.7,0.2,1.8,0.2z
			 M2.2,4.2C2.1,4.2,2,4.1,2,4s0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2C2.4,4.1,2.3,4.2,2.2,4.2z M3.3,3.5H1.2V0.6h2.1V3.5z"
                      />
                    </svg>
                  </a>
                  <p>
                    {lang === "EN" ? "Mobile" : ""}
                    {lang === "HE" ? "נייד" : ""}
                    {lang === "AR" ? "رقم المحمول" : ""}
                  </p>
                </div>
              ) : (
                ""
              )}
              {counter_button < 8 &&
              this.state.checked.alt_mobile === true &&
              this.state.alt_mobile !== undefined &&
              this.state.alt_mobile !== null ? (
                <div className="icons-div col-3" counter={counter_button++}>
                  <a href={"tel:" + this.state.alt_mobile}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 4.5 4.5"
                      className="svg-icon mobile-icon"
                      aria-hidden="true"
                    >
                      <path
                        id="XMLID_3_"
                        class="st0"
                        d="M3.2,0H1.3C1.1,0,1,0.1,1,0.3v3.8c0,0.2,0.1,0.3,0.3,0.3h1.9c0.2,0,0.3-0.1,0.3-0.3V0.3
			C3.5,0.1,3.4,0,3.2,0z M1.8,0.2h0.9c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1H1.8C1.7,0.4,1.7,0.3,1.8,0.2C1.7,0.2,1.7,0.2,1.8,0.2z
			 M2.2,4.2C2.1,4.2,2,4.1,2,4s0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2C2.4,4.1,2.3,4.2,2.2,4.2z M3.3,3.5H1.2V0.6h2.1V3.5z"
                      />
                    </svg>
                  </a>
                  <p>
                    {lang === "EN" ? "Alternative Mobile" : ""}
                    {lang === "HE" ? "טלפון נוסף" : ""}
                    {lang === "AR" ? "Alternative Mobile" : ""}
                  </p>
                </div>
              ) : (
                ""
              )}
              {counter_button < 8 &&
              this.state.checked.office_number === true &&
              this.state.office_number !== undefined &&
              this.state.office_number !== null ? (
                <div className="icons-div col-3" counter={counter_button++}>
                  <a href={"tel:" + this.state.office_number}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 4.5 4.5"
                      className="svg-icon phone-icon"
                      aria-hidden="true"
                    >
                      <path
                        class="st0"
                        d="M4.3,0.2L3.4,0C3.3,0,3.2,0,3.2,0.1l-0.4,1c0,0.1,0,0.2,0.1,0.2l0.5,0.4C3,2.5,2.5,3,1.8,3.3
	L1.4,2.8C1.3,2.7,1.2,2.7,1.1,2.8l-1,0.4C0,3.2,0,3.3,0,3.4l0.2,0.9c0,0.1,0.1,0.2,0.2,0.2c2.3,0,4.1-1.8,4.1-4.1
	C4.5,0.3,4.4,0.2,4.3,0.2z"
                      />
                    </svg>
                  </a>
                  <p>
                    {lang === "EN" ? "Office" : ""}
                    {lang === "HE" ? "משרד" : ""}
                    {lang === "AR" ? "رقم المكتب" : ""}
                  </p>
                </div>
              ) : (
                ""
              )}
              {counter_button < 8 &&
              this.state.checked.whatsapp === true &&
              this.state.whatsapp !== undefined &&
              this.state.whatsapp !== null ? (
                <div className="icons-div col-3" counter={counter_button++}>
                  {lang === "EN" ? (
                    <a
                      // href={
                      //   "whatsapp://send?text=Use the following link to view" +
                      //   this.state.name +
                      //   "’s digital card: " +
                      //   this.state.pageurl +
                      //   " %0a" +
                      //   `%0a%0aPowered by Easy Card ${APIModel.WEBSITE}` +
                      //   `&phone=${this.state.whatsapp}`
                      // }
                      href={`https://api.whatsapp.com/send?phone=${this.state.whatsapp}&text=Use the following link to view ${this.state.name}’s digital card: ${this.state.pageurl} %0a %0a%0aPowered by Easy Card ${APIModel.WEBSITE}`}
                      data-action="share/whatsapp/share"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 4.5 4.5"
                        className="svg-icon whatsapp-icon"
                        aria-hidden="true"
                      >
                        <path
                          class="st0"
                          d="M4.5,2.2c0,1.2-1,2.2-2.2,2.2c-0.4,0-0.8-0.1-1.1-0.3L0,4.5l0.4-1.2C0.2,3,0.1,2.6,0.1,2.2
		C0.1,1,1.1,0,2.3,0C3.5,0,4.5,1,4.5,2.2z M2.3,0.3c-1,0-1.9,0.8-1.9,1.8c0,0.4,0.1,0.8,0.4,1.1L0.6,4l0.7-0.2C1.6,3.9,1.9,4,2.3,4
		c1,0,1.9-0.8,1.9-1.8S3.3,0.3,2.3,0.3z M3.4,2.7c0,0,0,0-0.1-0.1C3.2,2.6,3,2.5,2.9,2.5c0,0-0.1,0-0.1,0c0,0.1-0.1,0.2-0.2,0.2
		c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.4-0.3C1.9,2.3,1.8,2.1,1.8,2.1c0-0.1,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1
		c0,0,0-0.1,0-0.1c0,0-0.1-0.3-0.2-0.4c0-0.1-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0s-0.1,0-0.1,0.1c0,0.1-0.2,0.2-0.2,0.4
		c0,0.3,0.2,0.5,0.2,0.6c0,0,0.4,0.6,0.9,0.8C2.9,3.3,2.9,3.2,3,3.2c0.1,0,0.3-0.1,0.4-0.3C3.4,2.8,3.4,2.7,3.4,2.7z"
                        />
                      </svg>
                    </a>
                  ) : (
                    ""
                  )}

                  {lang === "HE" ? (
                    <a
                      // href={
                      //   "whatsapp://send?text=לצפייה בכרטיס הביקור הדיגיטלי של " +
                      //   this.state.name +
                      //   this.state.pageurl +
                      //   " %0a" +
                      //   `%0a%0aPowered by Easy Card ${APIModel.WEBSITE}` +
                      //   `&phone=${this.state.whatsapp}`
                      // }
                      href={`https://api.whatsapp.com/send?phone=${this.state.whatsapp}&text=Use the following link to view ${this.state.name}’s digital card: ${this.state.pageurl} %0a %0a%0aPowered by Easy Card ${APIModel.WEBSITE}`}
                      data-action="share/whatsapp/share"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 4.5 4.5"
                        className="svg-icon whatsapp-icon"
                        aria-hidden="true"
                      >
                        <path
                          class="st0"
                          d="M4.5,2.2c0,1.2-1,2.2-2.2,2.2c-0.4,0-0.8-0.1-1.1-0.3L0,4.5l0.4-1.2C0.2,3,0.1,2.6,0.1,2.2
		C0.1,1,1.1,0,2.3,0C3.5,0,4.5,1,4.5,2.2z M2.3,0.3c-1,0-1.9,0.8-1.9,1.8c0,0.4,0.1,0.8,0.4,1.1L0.6,4l0.7-0.2C1.6,3.9,1.9,4,2.3,4
		c1,0,1.9-0.8,1.9-1.8S3.3,0.3,2.3,0.3z M3.4,2.7c0,0,0,0-0.1-0.1C3.2,2.6,3,2.5,2.9,2.5c0,0-0.1,0-0.1,0c0,0.1-0.1,0.2-0.2,0.2
		c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.4-0.3C1.9,2.3,1.8,2.1,1.8,2.1c0-0.1,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1
		c0,0,0-0.1,0-0.1c0,0-0.1-0.3-0.2-0.4c0-0.1-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0s-0.1,0-0.1,0.1c0,0.1-0.2,0.2-0.2,0.4
		c0,0.3,0.2,0.5,0.2,0.6c0,0,0.4,0.6,0.9,0.8C2.9,3.3,2.9,3.2,3,3.2c0.1,0,0.3-0.1,0.4-0.3C3.4,2.8,3.4,2.7,3.4,2.7z"
                        />
                      </svg>
                    </a>
                  ) : (
                    ""
                  )}

                  {lang === "AR" ? (
                    <a
                      // href={
                      //   "whatsapp://send?text=للتمعن في بطاقة العمل الالكترونيه ل " +
                      //   this.state.name +
                      //   this.state.pageurl +
                      //   " %0a" +
                      //   `%0a%0aPowered by Easy Card ${APIModel.WEBSITE}` +
                      //   `&phone=${this.state.whatsapp}`
                      // }
                      href={`https://api.whatsapp.com/send?phone=${this.state.whatsapp}&text=Use the following link to view ${this.state.name}’s digital card: ${this.state.pageurl} %0a %0a%0aPowered by Easy Card ${APIModel.WEBSITE}`}
                      data-action="share/whatsapp/share"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 4.5 4.5"
                        className="svg-icon whatsapp-icon"
                        aria-hidden="true"
                      >
                        <path
                          class="st0"
                          d="M4.5,2.2c0,1.2-1,2.2-2.2,2.2c-0.4,0-0.8-0.1-1.1-0.3L0,4.5l0.4-1.2C0.2,3,0.1,2.6,0.1,2.2
		C0.1,1,1.1,0,2.3,0C3.5,0,4.5,1,4.5,2.2z M2.3,0.3c-1,0-1.9,0.8-1.9,1.8c0,0.4,0.1,0.8,0.4,1.1L0.6,4l0.7-0.2C1.6,3.9,1.9,4,2.3,4
		c1,0,1.9-0.8,1.9-1.8S3.3,0.3,2.3,0.3z M3.4,2.7c0,0,0,0-0.1-0.1C3.2,2.6,3,2.5,2.9,2.5c0,0-0.1,0-0.1,0c0,0.1-0.1,0.2-0.2,0.2
		c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.4-0.3C1.9,2.3,1.8,2.1,1.8,2.1c0-0.1,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1
		c0,0,0-0.1,0-0.1c0,0-0.1-0.3-0.2-0.4c0-0.1-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0s-0.1,0-0.1,0.1c0,0.1-0.2,0.2-0.2,0.4
		c0,0.3,0.2,0.5,0.2,0.6c0,0,0.4,0.6,0.9,0.8C2.9,3.3,2.9,3.2,3,3.2c0.1,0,0.3-0.1,0.4-0.3C3.4,2.8,3.4,2.7,3.4,2.7z"
                        />
                      </svg>
                    </a>
                  ) : (
                    ""
                  )}

                  <p>
                    {lang === "EN" ? "WhatsApp" : ""}
                    {lang === "HE" ? "ווטסאפ" : ""}
                    {lang === "AR" ? "واتساب" : ""}
                  </p>
                </div>
              ) : (
                ""
              )}
              {counter_button < 8 &&
              this.state.checked.email === true &&
              this.state.email !== undefined &&
              this.state.email !== null ? (
                <div className="icons-div col-3" counter={counter_button++}>
                  {lang === "EN" ? (
                    <a
                      // href={
                      //   "mailto:" +
                      //   "" +
                      //   "?subject=" +
                      //   this.state.name +
                      //   "'s digital card%0D%0A" +
                      //   "&body=Use the link to view " +
                      //   this.state.name +
                      //   "’s digital card:%0D%0A" +
                      //   this.state.pageurl +
                      //   `%0D%0A %0D%0A Powered by Easy Card ${APIModel.WEBSITE}`
                      // }
                      href={`mailto:${this.state.email}?subject=${this.state.name}&body=Use the link to view ${this.state.name}'s digital card: %0D%0A${this.state.pageurl} %0D%0A %0D%0A Powered by Easy Card ${APIModel.WEBSITE}`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 4.5 4.5"
                        className="svg-icon envelope-icon"
                        aria-hidden="true"
                      >
                        <polygon
                          class="st0"
                          points="2.3,2.6 0.2,0.8 4.3,0.8 	"
                        />
                        <polygon class="st0" points="1.4,2.2 0,3.5 0,1 	" />
                        <polygon
                          class="st0"
                          points="1.6,2.4 2.3,3 2.9,2.4 4.3,3.7 0.2,3.7 	"
                        />
                        <polygon class="st0" points="3.1,2.2 4.5,1 4.5,3.5 	" />
                      </svg>
                    </a>
                  ) : (
                    ""
                  )}

                  {lang === "AR" ? (
                    <a
                      // href={
                      //   "mailto:" +
                      //   "" +
                      //   "?subject=" +
                      //   this.state.name +
                      //   "'s digital card%0D%0A" +
                      //   "&body=للتمعن في بطاقة العمل الالكترونيه ل " +
                      //   this.state.name +
                      //   this.state.pageurl +
                      //   `%0D%0A %0D%0A Powered by Easy Card ${APIModel.WEBSITE}`
                      // }
                      href={`mailto:${this.state.email}?subject=${this.state.name}&body=Use the link to view ${this.state.name}'s digital card: %0D%0A${this.state.pageurl} %0D%0A %0D%0A Powered by Easy Card ${APIModel.WEBSITE}`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 4.5 4.5"
                        className="svg-icon envelope-icon"
                        aria-hidden="true"
                      >
                        <polygon
                          class="st0"
                          points="2.3,2.6 0.2,0.8 4.3,0.8 	"
                        />
                        <polygon class="st0" points="1.4,2.2 0,3.5 0,1 	" />
                        <polygon
                          class="st0"
                          points="1.6,2.4 2.3,3 2.9,2.4 4.3,3.7 0.2,3.7 	"
                        />
                        <polygon class="st0" points="3.1,2.2 4.5,1 4.5,3.5 	" />
                      </svg>
                    </a>
                  ) : (
                    ""
                  )}
                  {lang === "HE" ? (
                    <a
                      // href={
                      //   "mailto:" +
                      //   this.state.email +
                      //   "?subject=" +
                      //   this.state.name +
                      //   "'s digital card%0D%0A" +
                      //   "&body=לצפייה בכרטיס הביקור הדיגיטלי של " +
                      //   this.state.name +
                      //   this.state.pageurl +
                      //   `%0D%0A %0D%0A Powered by Easy Card ${APIModel.WEBSITE}`
                      // }
                      href={`mailto:${this.state.email}?subject=${this.state.name}&body=Use the link to view ${this.state.name}'s digital card: %0D%0A${this.state.pageurl} %0D%0A %0D%0A Powered by Easy Card ${APIModel.WEBSITE}`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 4.5 4.5"
                        className="svg-icon envelope-icon"
                        aria-hidden="true"
                      >
                        <polygon
                          class="st0"
                          points="2.3,2.6 0.2,0.8 4.3,0.8 	"
                        />
                        <polygon class="st0" points="1.4,2.2 0,3.5 0,1 	" />
                        <polygon
                          class="st0"
                          points="1.6,2.4 2.3,3 2.9,2.4 4.3,3.7 0.2,3.7 	"
                        />
                        <polygon class="st0" points="3.1,2.2 4.5,1 4.5,3.5 	" />
                      </svg>
                    </a>
                  ) : (
                    ""
                  )}
                  <p>
                    {lang === "EN" ? "Email" : ""}
                    {lang === "HE" ? 'דוא"ל' : ""}
                    {lang === "AR" ? "البريد الإلكتروني" : ""}
                  </p>
                </div>
              ) : (
                ""
              )}
              {/* </div>
                          
                          <div id="row2-icons" className="row"> */}
              {counter_button < 8 &&
              this.state.checked.waze === true &&
              this.state.waze !== undefined &&
              this.state.waze !== null ? (
                <div className="icons-div col-3" counter={counter_button++}>
                  <a href={this.state.waze}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 4.5 4.5"
                      className="svg-icon waze-icon"
                      aria-hidden="true"
                    >
                      <g id="XMLID_12_">
                        <g id="XMLID_22_">
                          <g id="XMLID_23_">
                            <path
                              id="XMLID_24_"
                              class="st0"
                              d="M4.5,2c0-1-0.9-1.8-2-1.8c-1.1,0-2,0.8-2,1.8c0,0,0.1,0.4-0.1,0.6C0.4,2.6,0.3,2.7,0.1,2.7
					C0.1,2.7,0,2.7,0,2.8c0,0.4,0.3,0.7,0.9,0.8c0,0.1,0,0.1,0,0.2c0,0.3,0.3,0.6,0.6,0.6S2,4.1,2,3.8c0,0,0,0,0,0
					c0.2,0,0.4,0,0.6,0c0,0.3,0.3,0.6,0.6,0.6s0.6-0.3,0.6-0.6c0-0.1,0-0.2-0.1-0.3C4.1,3.2,4.5,2.6,4.5,2z M3.4,3.3
					c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0-0.4,0.1-0.5,0.3c0,0-0.1,0-0.1,0c-0.2,0-0.5,0-0.7,0C1.8,3.3,1.6,3.2,1.4,3.2
					C1.3,3.2,1.1,3.3,1,3.4C0.6,3.3,0.4,3.1,0.3,2.9c0.1,0,0.3-0.1,0.4-0.2C0.9,2.4,0.8,2,0.8,2c0-0.9,0.8-1.5,1.7-1.5
					S4.2,1.1,4.2,2C4.2,2.5,3.9,3,3.4,3.3z"
                            />
                          </g>
                        </g>
                        <g id="XMLID_19_">
                          <g id="XMLID_20_">
                            <path
                              id="XMLID_21_"
                              class="st0"
                              d="M2,1.4L2,1.4c-0.1,0-0.1,0.1-0.1,0.1S1.9,1.7,2,1.7s0.1-0.1,0.1-0.1S2.1,1.4,2,1.4z"
                            />
                          </g>
                        </g>
                        <g id="XMLID_16_">
                          <g id="XMLID_17_">
                            <path
                              id="XMLID_18_"
                              class="st0"
                              d="M3.1,1.4L3.1,1.4C3,1.4,3,1.5,3,1.5S3,1.7,3.1,1.7c0.1,0,0.1-0.1,0.1-0.1S3.2,1.4,3.1,1.4z"
                            />
                          </g>
                        </g>
                        <g id="XMLID_13_">
                          <g id="XMLID_14_">
                            <path
                              id="XMLID_15_"
                              class="st0"
                              d="M3.3,2.1c-0.1,0-0.2,0-0.2,0.1C3,2.5,2.8,2.7,2.5,2.7C2.3,2.7,2,2.5,2,2.2
					c0-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.1,0.1-0.1,0.2C1.8,2.7,2.1,3,2.5,3c0.4,0,0.8-0.3,0.8-0.7C3.4,2.2,3.3,2.1,3.3,2.1z"
                            />
                          </g>
                        </g>
                      </g>
                      <circle
                        id="XMLID_1_"
                        class="st0"
                        cx="1.4"
                        cy="3.8"
                        r="0.6"
                      />
                      <circle
                        id="XMLID_3_"
                        class="st0"
                        cx="3.1"
                        cy="3.8"
                        r="0.6"
                      />
                    </svg>
                  </a>
                  <p>
                    {lang === "EN" ? "Waze" : ""}
                    {lang === "HE" ? "ווייז" : ""}
                    {lang === "AR" ? "Waze" : ""}
                  </p>
                </div>
              ) : (
                ""
              )}
              {counter_button < 8 &&
              this.state.checked.website === true &&
              this.state.website !== undefined &&
              this.state.website !== null ? (
                <div className="icons-div col-3" counter={counter_button++}>
                  <a target="blank" href={"http://" + this.state.website}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 4.5 4.5"
                      className="svg-icon globe-icon"
                      aria-hidden="true"
                    >
                      <path
                        class="st0"
                        d="M4.4,3c0.1-0.2,0.1-0.5,0.1-0.7s0-0.5-0.1-0.7c0,0,0,0,0,0C4.1,0.6,3.2,0,2.3,0S0.4,0.6,0.1,1.5c0,0,0,0,0,0
	C0,1.7,0,2,0,2.3S0,2.8,0.1,3c0,0,0,0,0,0c0.3,0.9,1.1,1.5,2.1,1.5S4.1,3.9,4.4,3C4.4,3,4.4,3,4.4,3z M2.3,4.2C2.2,4.2,2,4,1.9,3.7
	C1.9,3.5,1.8,3.3,1.8,3.1h0.9c0,0.2-0.1,0.4-0.1,0.6C2.5,4,2.3,4.2,2.3,4.2z M1.7,2.8c0-0.2,0-0.4,0-0.5s0-0.4,0-0.5h1
	c0,0.2,0,0.4,0,0.5s0,0.4,0,0.5H1.7z M0.3,2.3c0-0.2,0-0.4,0.1-0.5h1c0,0.2,0,0.4,0,0.5c0,0.2,0,0.4,0,0.5h-1
	C0.3,2.6,0.3,2.4,0.3,2.3z M2.3,0.3c0.1,0,0.2,0.2,0.3,0.5C2.6,1,2.7,1.2,2.7,1.4H1.8c0-0.2,0.1-0.4,0.1-0.6C2,0.5,2.2,0.3,2.3,0.3z
	 M3.1,1.7h1c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5h-1c0-0.2,0-0.4,0-0.5S3.1,1.9,3.1,1.7z M4,1.4H3C3,1,2.9,0.6,2.8,0.4
	C3.3,0.5,3.7,0.9,4,1.4z M1.7,0.4C1.6,0.6,1.5,1,1.5,1.4H0.5C0.8,0.9,1.2,0.5,1.7,0.4z M0.5,3.1h0.9c0.1,0.4,0.2,0.8,0.3,1
	C1.2,4,0.8,3.6,0.5,3.1z M2.8,4.1C2.9,3.9,3,3.5,3,3.1H4C3.7,3.6,3.3,4,2.8,4.1z"
                      />
                    </svg>
                  </a>
                  <p>
                    {lang === "EN" ? "website" : ""}
                    {lang === "HE" ? "אתר" : ""}
                    {lang === "AR" ? "website" : ""}
                  </p>
                </div>
              ) : (
                ""
              )}
              {counter_button < 8 &&
              this.state.checked.facebook === true &&
              this.state.facebook !== undefined &&
              this.state.facebook !== null ? (
                <div className="icons-div col-3" counter={counter_button++}>
                  <a href={this.state.facebook}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 4.5 4.5"
                      className="svg-icon facebook-icon"
                      aria-hidden="true"
                    >
                      <path
                        class="st0"
                        d="M3.4,0L2.8,0C2.1,0,1.7,0.4,1.7,1.1v0.5H1.1C1.1,1.6,1,1.7,1,1.7v0.7c0,0.1,0,0.1,0.1,0.1h0.6
		v1.9c0,0.1,0,0.1,0.1,0.1h0.8c0.1,0,0.1,0,0.1-0.1V2.5h0.7c0.1,0,0.1,0,0.1-0.1l0-0.7c0,0,0,0,0-0.1c0,0,0,0-0.1,0H2.7V1.2
		c0-0.2,0-0.3,0.3-0.3l0.4,0c0.1,0,0.1,0,0.1-0.1L3.4,0C3.5,0,3.4,0,3.4,0z"
                      />
                    </svg>
                  </a>
                  <p>
                    {lang === "EN" ? "Facebook" : ""}
                    {lang === "HE" ? "פייסבוק" : ""}
                    {lang === "AR" ? "Facebook" : ""}
                  </p>
                </div>
              ) : (
                ""
              )}
              {counter_button < 8 &&
              this.state.checked.instagram === true &&
              this.state.instagram !== undefined &&
              this.state.instagram !== null ? (
                <div className="icons-div col-3" counter={counter_button++}>
                  <a href={this.state.instagram}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      className="svg-icon instagram-icon"
                      aria-hidden="true"
                    >
                      <path
                        class="st0"
                        d="M14.52,2.469H5.482c-1.664,0-3.013,1.349-3.013,3.013v9.038c0,1.662,1.349,3.012,3.013,3.012h9.038c1.662,0,3.012-1.35,3.012-3.012V5.482C17.531,3.818,16.182,2.469,14.52,2.469 M13.012,4.729h2.26v2.259h-2.26V4.729z M10,6.988c1.664,0,3.012,1.349,3.012,3.012c0,1.664-1.348,3.013-3.012,3.013c-1.664,0-3.012-1.349-3.012-3.013C6.988,8.336,8.336,6.988,10,6.988 M16.025,14.52c0,0.831-0.676,1.506-1.506,1.506H5.482c-0.831,0-1.507-0.675-1.507-1.506V9.247h1.583C5.516,9.494,5.482,9.743,5.482,10c0,2.497,2.023,4.52,4.518,4.52c2.494,0,4.52-2.022,4.52-4.52c0-0.257-0.035-0.506-0.076-0.753h1.582V14.52z"
                      ></path>
                    </svg>
                  </a>
                  <p>
                    {lang === "EN" ? "instagram" : ""}
                    {lang === "HE" ? "אינסטגרם" : ""}
                    {lang === "AR" ? "instagram" : ""}
                  </p>
                </div>
              ) : (
                ""
              )}
              {counter_button < 8 &&
              this.state.checked.youtube === true &&
              this.state.youtube !== undefined &&
              this.state.youtube !== null ? (
                <div className="icons-div col-3" counter={counter_button++}>
                  <a href={this.state.youtube}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      className="svg-icon youtube-icon"
                      aria-hidden="true"
                    >
                      <path
                        class="st0"
                        d="M9.426,7.625h0.271c0.596,0,1.079-0.48,1.079-1.073V4.808c0-0.593-0.483-1.073-1.079-1.073H9.426c-0.597,0-1.079,0.48-1.079,1.073v1.745C8.347,7.145,8.83,7.625,9.426,7.625 M9.156,4.741c0-0.222,0.182-0.402,0.404-0.402c0.225,0,0.405,0.18,0.405,0.402V6.62c0,0.222-0.181,0.402-0.405,0.402c-0.223,0-0.404-0.181-0.404-0.402V4.741z M12.126,7.625c0.539,0,1.013-0.47,1.013-0.47v0.403h0.81V3.735h-0.81v2.952c0,0-0.271,0.335-0.54,0.335c-0.271,0-0.271-0.202-0.271-0.202V3.735h-0.81v3.354C11.519,7.089,11.586,7.625,12.126,7.625 M6.254,7.559H7.2v-2.08l1.079-2.952H7.401L6.727,4.473L6.052,2.527H5.107l1.146,2.952V7.559z M11.586,12.003c-0.175,0-0.312,0.104-0.405,0.204v2.706c0.086,0.091,0.213,0.18,0.405,0.18c0.405,0,0.405-0.451,0.405-0.451v-2.188C11.991,12.453,11.924,12.003,11.586,12.003 M14.961,8.463c0,0-2.477-0.129-4.961-0.129c-2.475,0-4.96,0.129-4.96,0.129c-1.119,0-2.025,0.864-2.025,1.93c0,0-0.203,1.252-0.203,2.511c0,1.252,0.203,2.51,0.203,2.51c0,1.066,0.906,1.931,2.025,1.931c0,0,2.438,0.129,4.96,0.129c2.437,0,4.961-0.129,4.961-0.129c1.117,0,2.024-0.864,2.024-1.931c0,0,0.202-1.268,0.202-2.51c0-1.268-0.202-2.511-0.202-2.511C16.985,9.328,16.078,8.463,14.961,8.463 M7.065,10.651H6.052v5.085H5.107v-5.085H4.095V9.814h2.97V10.651z M9.628,15.736h-0.81v-0.386c0,0-0.472,0.45-1.012,0.45c-0.54,0-0.606-0.515-0.606-0.515v-3.991h0.809v3.733c0,0,0,0.193,0.271,0.193c0.27,0,0.54-0.322,0.54-0.322v-3.604h0.81V15.736z M12.801,14.771c0,0,0,1.03-0.742,1.03c-0.455,0-0.73-0.241-0.878-0.429v0.364h-0.876V9.814h0.876v1.92c0.135-0.142,0.464-0.439,0.878-0.439c0.54,0,0.742,0.45,0.742,1.03V14.771z M15.973,12.39v1.287h-1.688v0.965c0,0,0,0.451,0.405,0.451s0.405-0.451,0.405-0.451v-0.45h0.877v0.708c0,0-0.136,0.901-1.215,0.901c-1.08,0-1.282-0.901-1.282-0.901v-2.51c0,0,0-1.095,1.282-1.095S15.973,12.39,15.973,12.39 M14.69,12.003c-0.405,0-0.405,0.45-0.405,0.45v0.579h0.811v-0.579C15.096,12.453,15.096,12.003,14.69,12.003"
                      ></path>
                    </svg>
                  </a>
                  <p>
                    {lang === "EN" ? "Youtube" : ""}
                    {lang === "HE" ? "יוטיוב" : ""}
                    {lang === "AR" ? "Youtube" : ""}
                  </p>
                </div>
              ) : (
                ""
              )}
              {counter_button < 8 &&
              this.state.checked.twitter === true &&
              this.state.twitter !== undefined &&
              this.state.twitter !== null ? (
                <div className="icons-div col-3" counter={counter_button++}>
                  <a href={this.state.twitter}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      className="svg-icon twitter-icon"
                      aria-hidden="true"
                    >
                      <path
                        class="st0"
                        d="M18.258,3.266c-0.693,0.405-1.46,0.698-2.277,0.857c-0.653-0.686-1.586-1.115-2.618-1.115c-1.98,0-3.586,1.581-3.586,3.53c0,0.276,0.031,0.545,0.092,0.805C6.888,7.195,4.245,5.79,2.476,3.654C2.167,4.176,1.99,4.781,1.99,5.429c0,1.224,0.633,2.305,1.596,2.938C2.999,8.349,2.445,8.19,1.961,7.925C1.96,7.94,1.96,7.954,1.96,7.97c0,1.71,1.237,3.138,2.877,3.462c-0.301,0.08-0.617,0.123-0.945,0.123c-0.23,0-0.456-0.021-0.674-0.062c0.456,1.402,1.781,2.422,3.35,2.451c-1.228,0.947-2.773,1.512-4.454,1.512c-0.291,0-0.575-0.016-0.855-0.049c1.588,1,3.473,1.586,5.498,1.586c6.598,0,10.205-5.379,10.205-10.045c0-0.153-0.003-0.305-0.01-0.456c0.7-0.499,1.308-1.12,1.789-1.827c-0.644,0.28-1.334,0.469-2.06,0.555C17.422,4.782,17.99,4.091,18.258,3.266"
                      ></path>
                    </svg>
                  </a>
                  <p>
                    {lang === "EN" ? "Twitter" : ""}
                    {lang === "HE" ? "טוויטר" : ""}
                    {lang === "AR" ? "Twitter" : ""}
                  </p>
                </div>
              ) : (
                ""
              )}
              {counter_button < 8 &&
              this.state.checked.linkdin === true &&
              this.state.linkdin !== undefined &&
              this.state.linkdin !== null ? (
                <div className="icons-div col-3" counter={counter_button++}>
                  <a href={this.state.linkdin}>
                    <svg
                      className="svg-icon linked-icon"
                      aria-hidden="true"
                      viewBox="0 0 4.5 4.5"
                    >
                      <path
                        class="st0"
                        d="M1,4.5H0.1v-3H1V4.5z M0.5,1.1C0.2,1.1,0,0.8,0,0.5C0,0.2,0.2,0,0.5,0c0.3,0,0.5,0.2,0.5,0.5
		C1.1,0.8,0.8,1.1,0.5,1.1z M4.5,4.5H3.6V3c0-0.3,0-0.8-0.5-0.8C2.6,2.2,2.5,2.6,2.5,3v1.5H1.6v-3h0.9v0.4h0c0.1-0.2,0.4-0.5,0.9-0.5
		c0.9,0,1.1,0.6,1.1,1.4L4.5,4.5L4.5,4.5z"
                      />
                    </svg>
                  </a>

                  <p>
                    {lang === "EN" ? "Linkedin" : ""}
                    {lang === "HE" ? "לינקדאין" : ""}
                    {lang === "AR" ? "Linkedin" : ""}
                  </p>
                </div>
              ) : (
                ""
              )}
              {counter_button < 8 &&
              this.state.checked.google_plus === true &&
              this.state.google_plus !== undefined &&
              this.state.google_plus !== null ? (
                <div className="icons-div col-3" counter={counter_button++}>
                  <a href={"skype:" + this.state.google_plus + "?chat"}>
                    <svg
                      className="svg-icon google_plus-icon"
                      aria-hidden="true"
                      viewBox="0 0 24 16"
                    >
                      <path d="M22.987 13.966c1.357-7.765-5.416-14.412-13.052-12.979-5.821-3.561-12.503 3.226-8.935 9.029-1.387 7.747 5.384 14.48 13.083 13.01 5.832 3.536 12.493-3.26 8.904-9.06zm-10.603 5.891c-3.181 0-6.378-1.448-6.362-3.941.005-.752.564-1.442 1.309-1.442 1.873 0 1.855 2.795 4.837 2.795 2.093 0 2.807-1.146 2.807-1.944 0-2.886-9.043-1.117-9.043-6.543 0-2.938 2.402-4.962 6.179-4.741 3.602.213 5.713 1.803 5.917 3.289.101.971-.542 1.727-1.659 1.727-1.628 0-1.795-2.181-4.6-2.181-1.266 0-2.334.528-2.334 1.674 0 2.395 8.99 1.005 8.99 6.276-.001 3.039-2.423 5.031-6.041 5.031z" />
                    </svg>
                  </a>
                  <p>
                    {lang === "EN" ? "Skype" : ""}
                    {lang === "HE" ? "סקייפ" : ""}
                    {lang === "AR" ? "Skype" : ""}
                  </p>
                </div>
              ) : (
                ""
              )}
              {counter_button < 8 &&
              this.state.checked.fax === true &&
              this.state.fax !== undefined &&
              this.state.fax !== null ? (
                <div className="icons-div col-3" counter={counter_button++}>
                  <a href={"fax:" + this.state.fax}>
                    <svg
                      className="svg-icon fax-icon"
                      aria-hidden="true"
                      viewBox="0 0 4.5 4.5"
                    >
                      <path
                        class="st0"
                        d="M4.3,2.1C4.2,2,4.1,1.9,4,1.9H3.8V1.2c0-0.1,0-0.2-0.1-0.2c0-0.1-0.1-0.2-0.1-0.2L3.2,0.4
		C3.2,0.3,3.1,0.3,3,0.2c-0.1,0-0.2-0.1-0.2-0.1H1c-0.1,0-0.1,0-0.2,0.1C0.7,0.3,0.7,0.4,0.7,0.4v1.5H0.5C0.4,1.9,0.3,2,0.2,2.1
		C0.1,2.2,0,2.3,0,2.4v1.1c0,0,0,0,0,0.1c0,0,0,0,0.1,0h0.6v0.4c0,0.1,0,0.1,0.1,0.2C0.8,4.3,0.9,4.3,1,4.3h2.6c0.1,0,0.1,0,0.2-0.1
		c0.1-0.1,0.1-0.1,0.1-0.2V3.6h0.6c0,0,0,0,0.1,0c0,0,0,0,0-0.1V2.4C4.5,2.3,4.4,2.2,4.3,2.1z M3.5,4H1V3.3h2.4V4z M3.5,2.2H1V0.5
		h1.7V1c0,0.1,0,0.1,0.1,0.2C2.9,1.2,3,1.2,3,1.2h0.4L3.5,2.2L3.5,2.2z M4.1,2.5c0,0-0.1,0.1-0.1,0.1s-0.1,0-0.1-0.1
		c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1S4.1,2.5,4.1,2.5z"
                      />
                    </svg>
                  </a>
                  <p>
                    {lang === "EN" ? "Fax" : ""}
                    {lang === "HE" ? "פקס" : ""}
                    {lang === "AR" ? "رقم الفاكس" : ""}
                  </p>
                </div>
              ) : (
                ""
              )}

              {/* -------------tiktok starts--------------- */}

              {counter_button < 8 &&
              this.state.checked.tiktok === true &&
              this.state.tiktok !== undefined &&
              this.state.tiktok !== null ? (
                <div className="icons-div col-3" counter={counter_button++}>
                  <a target="blank" href={this.state.tiktok}>
                    <svg
                      className="svg-icon"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path
                        class="st0"
                        d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"
                      />
                    </svg>
                  </a>
                  <p>
                    {lang === "EN" ? "TikTok" : ""}
                    {lang === "HE" ? "טיקטוק" : ""}
                    {lang === "AR" ? "TikTok" : ""}
                  </p>
                </div>
              ) : (
                ""
              )}

              {/* -------------tiktok ends--------------- */}
            </div>

            <div className="reach-us-button">
              <center>
                <a
                  id="add-contact"
                  className="btn-lg mx-auto"
                  href={this.state.vcard}
                >
                  <svg
                    className="svg-icon"
                    aria-hidden="true"
                    viewBox="0 0 20 20"
                  >
                    <path
                      class="st0"
                      d="M14.613,10c0,0.23-0.188,0.419-0.419,0.419H10.42v3.774c0,0.23-0.189,0.42-0.42,0.42s-0.419-0.189-0.419-0.42v-3.774H5.806c-0.23,0-0.419-0.189-0.419-0.419s0.189-0.419,0.419-0.419h3.775V5.806c0-0.23,0.189-0.419,0.419-0.419s0.42,0.189,0.42,0.419v3.775h3.774C14.425,9.581,14.613,9.77,14.613,10 M17.969,10c0,4.401-3.567,7.969-7.969,7.969c-4.402,0-7.969-3.567-7.969-7.969c0-4.402,3.567-7.969,7.969-7.969C14.401,2.031,17.969,5.598,17.969,10 M17.13,10c0-3.932-3.198-7.13-7.13-7.13S2.87,6.068,2.87,10c0,3.933,3.198,7.13,7.13,7.13S17.13,13.933,17.13,10"
                    ></path>
                  </svg>
                  {lang === "EN" ? "Add to contacts" : ""}
                  {lang === "HE" ? "הוסף לאנשי הקשר" : ""}
                  {lang === "AR" ? "Add to contacts" : ""}
                </a>
              </center>
            </div>
          </div>
        </div>

        {this.state.field_value ? (
          <div id="about-us-background">
            <div className="container">
              <div className="row">
                <div id="about-us-container" className="col-12">
                  <div id="about-us-content">
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 mx-auto my-auto p-3">
                      <center>
                        <div id="background-wrapper">
                          <span id="about-us-title">{t.Background[lang]}</span>
                        </div>
                        {/* <p> */}
                        {this.state.field_value
                          ? this.state.field_value
                              .split("\n")
                              .map((item, i) =>
                                item ? <p key={i}>{item}</p> : <br />
                              )
                          : ""}
                        {/* {this.state.field_value} */}
                        {/* </p> */}
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}

        <div id="dropdown-background">
          <div className="container">
            <div className="row">
              <div id="dropdown-container" className="col-12">
                <div
                  className="ftco-section ftco-no-pb col-12 p-0"
                  id="resume-section"
                >
                  {this.state.checked.about_us && this.state.about_us.text ? (
                    <div className="resume-wrap">
                      <span className="date">
                        <button
                          type="button"
                          className="btn btn-info"
                          data-toggle="collapse"
                          data-target="#collapse-text"
                        >
                          <i className="ion-2x">
                            <img src="/assets/images/digital-card-icons/About us.png" />
                          </i>
                          <p className="info-prop-p dropdown-paragraph-extension">
                            {t.AboutUs[lang]}
                          </p>
                        </button>
                        <div
                          id="collapse-text"
                          className="collapse text collapse-text"
                        >
                          <div className="the-padder-b">
                            {this.state.about_us.text
                              ? this.state.about_us.text
                                  .split("\n")
                                  .map((item, i) =>
                                    item ? <h6 key={i}>{item}</h6> : <br />
                                  )
                              : ""}
                            {/* <h6>{this.state.about_us.text}</h6> */}
                          </div>
                        </div>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {this.state.checked.qr_code == true ? (
                    <div className="resume-wrap">
                      <span className="date">
                        <button
                          type="button"
                          className="btn btn-info"
                          data-toggle="collapse"
                          data-target="#collapse-text1"
                        >
                          <i className="ion-2x">
                            {/* <img src="/assets/images/digital-card-icons/qr_code.png" /> */}
                          </i>
                          <p className="info-prop-p dropdown-paragraph-extension">
                            {t.ScanQRCode[lang]}
                          </p>
                        </button>
                        <div
                          id="collapse-text1"
                          className="collapse text collapse-text"
                        >
                          <div
                            className="the-padder-b"
                            style={{ marginLeft: "70px" }}
                          >
                            <QRCode
                              value={window.location.href}
                              size={150}
                              bgColor={"#ffffff"}
                              fgColor={"#000000"}
                              level={"L"}
                              includeMargin={false}
                              renderAs={"canvas"}
                              imageSettings={{
                                src: "http://localhost:3001/favicon.ico",
                                x: null,
                                y: null,
                                height: 18,
                                width: 18,
                                excavate: true,
                              }}
                            />
                          </div>
                        </div>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.checked.our_vision &&
                  this.state.our_vision.text ? (
                    <div className="resume-wrap">
                      <span className="date">
                        <button
                          type="button"
                          className="btn btn-info"
                          data-toggle="collapse"
                          data-target="#collapse-text1"
                        >
                          <i className="ion-2x">
                            <img src="/assets/images/digital-card-icons/Our vision.png" />
                          </i>
                          <p className="info-prop-p dropdown-paragraph-extension">
                            {t.OurVision[lang]}
                          </p>
                        </button>
                        <div
                          id="collapse-text1"
                          className="collapse text collapse-text"
                        >
                          <div className="the-padder-b">
                            {this.state.our_vision.text
                              ? this.state.our_vision.text
                                  .split("\n")
                                  .map((item, i) =>
                                    item ? <h6 key={i}>{item}</h6> : <br />
                                  )
                              : ""}
                            {/* <h6>{this.state.our_vision.text}</h6> */}
                          </div>
                        </div>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.checked.our_application &&
                  this.state.our_application.text ? (
                    <div className="resume-wrap">
                      <span className="date">
                        <button
                          type="button"
                          className="btn btn-info"
                          data-toggle="collapse"
                          data-target="#collapse-text2"
                        >
                          <i className="ion-2x">
                            <img src="/assets/images/digital-card-icons/Our application.png" />
                          </i>
                          <p className="info-prop-p dropdown-paragraph-extension">
                            {t.OurApplication[lang]}
                          </p>
                        </button>
                        <div
                          id="collapse-text2"
                          className="collapse text collapse-text"
                        >
                          <div className="the-padder-b">
                            {this.state.our_application.text
                              ? this.state.our_application.text
                                  .split("\n")
                                  .map((item, i) =>
                                    item ? <h6 key={i}>{item}</h6> : <br />
                                  )
                              : ""}
                            {/* <h6>{this.state.our_application.text}</h6> */}
                            <br />
                            <div className="container">
                              <div className="row mx-auto store-row">
                                {this.state.our_application.apple_url ? (
                                  <div className="col-xs-6 mx-auto">
                                    <div height="40px;">
                                      <a
                                        href={
                                          this.state.our_application.apple_url
                                        }
                                      >
                                        <img
                                          src="/assets/images/business/apple.png"
                                          height="42px"
                                          width="125"
                                          alt="apple store"
                                        />
                                      </a>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {this.state.our_application.google_url ? (
                                  <div className="col-xs-6 mx-auto apple-store">
                                    <div height="40px;">
                                      <a
                                        href={
                                          this.state.our_application.google_url
                                        }
                                      >
                                        <img
                                          src="/assets/images/business/google.png"
                                          height="42px"
                                          width="125"
                                          alt="apple store"
                                        />
                                      </a>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.checked.activity_hour ? (
                    <div className="resume-wrap">
                      <span className="date">
                        <button
                          type="button"
                          className="btn btn-info"
                          data-toggle="collapse"
                          data-target="#collapse-text3"
                        >
                          <i className="ion-2x">
                            <img src="/assets/images/digital-card-icons/Acivity hours.png" />
                          </i>
                          <p className="info-prop-p dropdown-paragraph-extension">
                            {t.ActivityHour[lang]}
                          </p>
                        </button>
                        <div
                          id="collapse-text3"
                          className="collapse text collapse-text"
                        >
                          <div className="the-padder-b">
                            {this.state.activity_hour.map((one, index) =>
                              index !== 0 ? (
                                <div
                                  className={
                                    index === 0
                                      ? "carousel-item active"
                                      : "carousel-item"
                                  }
                                  key={index}
                                >
                                  {one
                                    ? one
                                        .split("\n")
                                        .map((item, i) =>
                                          item ? (
                                            <h6 key={i}>{item}</h6>
                                          ) : (
                                            <br />
                                          )
                                        )
                                    : ""}
                                  <hr />
                                  {/* <h6>{one}</h6> <hr/> */}
                                </div>
                              ) : (
                                ""
                              )
                            )}
                          </div>
                        </div>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.checked.employees ? (
                    <div className="resume-wrap">
                      <span className="date">
                        <button
                          type="button"
                          className="btn btn-info"
                          data-toggle="collapse"
                          data-target="#collapse-text4"
                        >
                          <i className="ion-2x">
                            <img src="/assets/images/digital-card-icons/Staff.png" />
                          </i>
                          <p className="info-prop-p dropdown-paragraph-extension">
                            {t.Employees[lang]}
                          </p>
                        </button>
                        <div
                          id="collapse-text4"
                          className="collapse text collapse-text"
                        >
                          {this.state.employees.map((one, index) =>
                            index !== 0 ? (
                              <div className="the-padder-b" key={index}>
                                <h6>
                                  <a href={one.link} target="blank">
                                    {one.title}
                                  </a>
                                </h6>
                                {/* <h6><a href={one.link} target="blank">{one.link}</a></h6> */}
                                <hr />
                              </div>
                            ) : (
                              ""
                            )
                          )}
                        </div>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.checked.our_stories ? (
                    <div className="resume-wrap">
                      <span className="date">
                        <button
                          type="button"
                          className="btn btn-info"
                          data-toggle="collapse"
                          data-target="#collapse-text5"
                        >
                          <i className="ion-2x">
                            <img src="/assets/images/digital-card-icons/Success stories.png" />
                          </i>
                          <p className="info-prop-p dropdown-paragraph-extension">
                            {t.SuccessStories[lang]}
                          </p>
                        </button>
                        <div
                          id="collapse-text5"
                          className="collapse text collapse-text"
                        >
                          <div className="the-padder-b">
                            <div
                              id="carouselExampleSlidesOnly"
                              className="carousel slide"
                              data-ride="carousel"
                            >
                              <div className="carousel-inner">
                                {this.state.our_stories.map((one, index) =>
                                  index !== 0 ? (
                                    <div
                                      className={
                                        index === 0
                                          ? "carousel-item active"
                                          : "carousel-item"
                                      }
                                      key={index}
                                    >
                                      {one
                                        ? one
                                            .split("\n")
                                            .map((item, i) =>
                                              item ? (
                                                <h6 key={i}>{item}</h6>
                                              ) : (
                                                <br />
                                              )
                                            )
                                        : ""}
                                      <hr />
                                      {/* <h6>{one}</h6> <hr/> */}
                                    </div>
                                  ) : (
                                    ""
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.checked.recommendations &&
                  this.state.recommendations[0].link ? (
                    <div className="resume-wrap">
                      <span className="date">
                        <a
                          target="blank"
                          href={this.state.recommendations[0].link}
                          className="btn btn-info"
                          style={{
                            border: "unset",
                            background: "unset",
                            fontSize: "1em",
                          }}
                        >
                          <i className="ion-2x">
                            <img src="/assets/images/digital-card-icons/Recommendations.png" />
                          </i>
                          <p className="info-prop-p dropdown-paragraph-extension">
                            {t.Recommendations[lang]}
                          </p>
                        </a>
                        {/* <div id="collapse-text6" className="collapse text collapse-text">
                                                  {this.state.recommendations.map((one, index) => (
                                                      (index !== 0) ?
                                                      <div className="the-padder-b" key={index}>
                                                          <h6>{one.title}</h6>
                                                          <h6><a href={one.link}>{one.link}</a></h6>
                                                          <hr/>
                                                      </div> : ''
                                                  ))}
                                              </div> */}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.checked.articles ? (
                    <div className="resume-wrap">
                      <span className="date">
                        <button
                          type="button"
                          className="btn btn-info"
                          data-toggle="collapse"
                          data-target="#collapse-text7"
                        >
                          <i className="ion-2x">
                            <img src="/assets/images/digital-card-icons/Articles.png" />
                          </i>
                          <p className="info-prop-p dropdown-paragraph-extension">
                            {t.ArticleTitle[lang]}
                          </p>
                        </button>
                        <div
                          id="collapse-text7"
                          className="collapse text collapse-text"
                        >
                          {this.state.articles.map((one, index) =>
                            index !== 0 ? (
                              <div className="the-padder-b" key={index}>
                                <h5>{one.title}</h5>
                                <h6>
                                  <a href={one.link}>{one.link}</a>
                                </h6>
                                <hr />
                              </div>
                            ) : (
                              ""
                            )
                          )}
                        </div>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* survey */}

                  {this.state.checked.survey ? (
                    <div className="resume-wrap">
                      <span className="date">
                        <button
                          type="button"
                          className="btn btn-info"
                          data-toggle="collapse"
                          data-target="#collapse-text11"
                        >
                          <i className="ion-2x">
                            <img src="/assets/images/digital-card-icons/Articles.png" />
                          </i>
                          <p className="info-prop-p dropdown-paragraph-extension">
                            {t.Survey[lang]}
                          </p>
                        </button>

                        <div
                          id="collapse-text11"
                          className="collapse text collapse-text"
                        >
                          {/* {this.state.survey_check == 2 ? ( */}
                          <div id="survey_hide">
                            <div style={{ marginTop: "20px" }}>
                              {this.state.survey[0].title}
                            </div>
                            <table style={{ width: "100%" }}>
                              <tbody>
                                <tr>
                                  <td>
                                    <div style={{ marginTop: "20px" }}>
                                      {this.state.survey.map((one, index) =>
                                        index !== 0 ? (
                                          <div key={index}>
                                            <div className="issue">
                                              <span class="group1">
                                                <input
                                                  id="radio-C1"
                                                  type="radio"
                                                  value={one}
                                                  name="survey_ans"
                                                  onChange={this.onValueChange}
                                                ></input>
                                                <label
                                                  for="radio-C1"
                                                  style={{
                                                    paddingLeft: "10px",
                                                  }}
                                                >
                                                  {one}
                                                </label>
                                              </span>
                                            </div>
                                            <hr />
                                          </div>
                                        ) : (
                                          ""
                                        )
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <button
                              className="btn btn-send-message"
                              type="button"
                              onClick={this.submit_survey}
                            >
                              {/* Submit Survey */}
                              {t.submitSurvey[lang]}
                            </button>
                          </div>
                          {/* ) : (
                            <div className="row">
                              <div className="col-md-12">
                                <HorizontalBar data={data} options={options1} />
                              </div>
                            </div>
                          )} */}
                        </div>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* end survey */}

                  {this.state.checked.leave_a_message ? (
                    <div className="resume-wrap">
                      <span className="date">
                        <button
                          type="button"
                          className="btn btn-info"
                          data-toggle="collapse"
                          data-target="#collapse-text8"
                        >
                          <i className="ion-2x">
                            <img src="/assets/images/digital-card-icons/Leave a message.png" />
                          </i>
                          <p className="info-prop-p dropdown-paragraph-extension">
                            {this.state.leave_a_message_label
                              ? t.Leaveusamessage[lang]
                              : t.Leaveusamessage[lang]}
                          </p>
                        </button>
                        <div
                          id="collapse-text8"
                          className="collapse text collapse-text"
                        >
                          <br />
                          <br />
                          <form>
                            <input
                              type="text"
                              className="form-control"
                              id="message_name"
                              name="message_name"
                              value={this.state.message_name}
                              onChange={this.onChange}
                              placeholder={t.contactusfname[lang]}
                              required
                            />
                            <br />
                            <input
                              type="text"
                              className="form-control"
                              id="message_phone"
                              name="message_phone"
                              value={this.state.message_phone}
                              onChange={this.onChange}
                              placeholder={t.contactusphonenumber[lang]}
                            />
                            <br />
                            <input
                              type="email"
                              className="form-control email-area"
                              id="message_email"
                              name="message_email"
                              value={this.state.message_email}
                              onChange={this.onChange}
                              placeholder={t.contactusEmail[lang]}
                              required
                            />
                            <br />
                            <textarea
                              className="form-control textarea-text"
                              id="message_message"
                              name="message_message"
                              rows="6"
                              value={this.state.message_message}
                              onChange={this.onChange}
                              placeholder={t.leaveamessage[lang]}
                            ></textarea>
                            <br />
                            <button
                              type="button"
                              className="btn btn-send-message"
                              onClick={this.leaveamessage}
                            >
                              {t.Sendmessage[lang]}
                            </button>
                          </form>
                        </div>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="img-slider-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-12">
                {this.state.images !== undefined ? (
                  <Carousel
                    renderCenterLeftControls={({ previousSlide }) => (
                      <button
                        type="button"
                        id="carousel_buttons"
                        onClick={previousSlide}
                      >
                        {t.prev[lang]}
                      </button>
                    )}
                    renderCenterRightControls={({ nextSlide }) => (
                      <button
                        type="button"
                        id="carousel_buttons"
                        onClick={nextSlide}
                      >
                        {t.next[lang]}
                      </button>
                    )}
                    wrapAround
                    autoplay="true"
                    heightMode="current"
                  >
                    {this.state.images.map((one, index) => (
                      <img src={one} alt="" key={index} />
                    ))}
                  </Carousel>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>

        <div id="video-background">
          <div className="video-container-wrapper">{this.renderVideo()}</div>
        </div>
        <div id="share-link-background">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <p className="share-link-icons-wrapper ">
                {lang === "EN" ? "Share this card via:" : ""}
                {lang === "HE" ? "שתף הכרטיס באמצעות" : ""}
                {lang === "AR" ? "شارك بطاقتك بسهولة" : ""}
              </p>
            </div>
            <div className="row justify-content-center">
              <div className=" mx-auto my-auto share-link-icons h-100">
                <button type="button" className="btn btn-circle btn-lg">
                  {lang === "EN" ? (
                    <a
                      // href={
                      //   "mailto:" +
                      //   "" +
                      //   "?subject=" +
                      //   this.state.name +
                      //   "'s digital card%0D%0A" +
                      //   "&body=Use the link to view " +
                      //   this.state.name +
                      //   " " +
                      //   "’s digital card:%0D%0A" +
                      //   " " +
                      //   this.state.pageurl +
                      //   `%0D%0A %0D%0A Powered by Easy Card ${APIModel.WEBSITE}`
                      // }
                      href={`mailto:?subject=${this.state.name}&body=Use the link to view ${this.state.name}'s digital card: %0D%0A${this.state.pageurl} %0D%0A %0D%0A Powered by Easy Card ${APIModel.WEBSITE}`}
                    >
                      <svg
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 4.5 4.5"
                      >
                        <polygon
                          class="st0"
                          points="2.3,2.6 0.2,0.8 4.3,0.8 	"
                        />
                        <polygon class="st0" points="1.4,2.2 0,3.5 0,1 	" />
                        <polygon
                          class="st0"
                          points="1.6,2.4 2.3,3 2.9,2.4 4.3,3.7 0.2,3.7 	"
                        />
                        <polygon class="st0" points="3.1,2.2 4.5,1 4.5,3.5 	" />
                      </svg>
                    </a>
                  ) : null}

                  {lang === "HE" ? (
                    <a
                      // href={
                      //   "mailto: " +
                      //   "" +
                      //   "?subject=" +
                      //   this.state.name +
                      //   "'s digital card%0D%0A" +
                      //   "&body=לצפייה בכרטיס הביקור הדיגיטלי של " +
                      //   this.state.name +
                      //   " " +
                      //   this.state.pageurl +
                      //   " " +
                      //   `%0D%0A %0D%0A Powered by Easy Card ${APIModel.WEBSITE}`
                      // }
                      href={`mailto:?subject=${this.state.name}&body=Use the link to view ${this.state.name}'s digital card: %0D%0A${this.state.pageurl} %0D%0A %0D%0A Powered by Easy Card ${APIModel.WEBSITE}`}
                    >
                      <svg
                        aria-hidden="true"
                        viewBox="0 0 4.5 4.5"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polygon
                          class="st0"
                          points="2.3,2.6 0.2,0.8 4.3,0.8 	"
                        />
                        <polygon class="st0" points="1.4,2.2 0,3.5 0,1 	" />
                        <polygon
                          class="st0"
                          points="1.6,2.4 2.3,3 2.9,2.4 4.3,3.7 0.2,3.7 	"
                        />
                        <polygon class="st0" points="3.1,2.2 4.5,1 4.5,3.5 	" />
                      </svg>
                    </a>
                  ) : (
                    ""
                  )}

                  {lang === "AR" ? (
                    <a
                      // href={
                      //   "mailto: " +
                      //   "" +
                      //   "?subject=" +
                      //   this.state.name +
                      //   "'s digital card%0D%0A" +
                      //   "&body=للتمعن في بطاقة العمل الالكترونيه ل" +
                      //   this.state.name +
                      //   " " +
                      //   this.state.pageurl +
                      //   " " +
                      //   `%0D%0A %0D%0A Powered by Easy Card ${APIModel.WEBSITE}`
                      // }
                      href={`mailto:?subject=${this.state.name}&body=Use the link to view ${this.state.name}'s digital card: %0D%0A${this.state.pageurl} %0D%0A %0D%0A Powered by Easy Card ${APIModel.WEBSITE}`}
                    >
                      <svg
                        className="svg-icon envelope-icon"
                        aria-hidden="true"
                        viewBox="0 0 4.5 4.5"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polygon
                          class="st0"
                          points="2.3,2.6 0.2,0.8 4.3,0.8 	"
                        />
                        <polygon class="st0" points="1.4,2.2 0,3.5 0,1 	" />
                        <polygon
                          class="st0"
                          points="1.6,2.4 2.3,3 2.9,2.4 4.3,3.7 0.2,3.7 	"
                        />
                        <polygon class="st0" points="3.1,2.2 4.5,1 4.5,3.5 	" />
                      </svg>
                    </a>
                  ) : (
                    ""
                  )}
                </button>
                &nbsp;&nbsp;
                <button type="button" className="btn btn-circle btn-lg ">
                  <a
                    href={
                      "https://www.facebook.com/sharer/sharer.php?u=" +
                      this.state.facebook
                    }
                  >
                    <svg
                      className="svg-icon"
                      aria-hidden="true"
                      viewBox="0 0 4.5 4.5"
                    >
                      <path
                        class="st0"
                        d="M3.4,0L2.8,0C2.1,0,1.7,0.4,1.7,1.1v0.5H1.1C1.1,1.6,1,1.7,1,1.7v0.7c0,0.1,0,0.1,0.1,0.1h0.6
		v1.9c0,0.1,0,0.1,0.1,0.1h0.8c0.1,0,0.1,0,0.1-0.1V2.5h0.7c0.1,0,0.1,0,0.1-0.1l0-0.7c0,0,0,0,0-0.1c0,0,0,0-0.1,0H2.7V1.2
		c0-0.2,0-0.3,0.3-0.3l0.4,0c0.1,0,0.1,0,0.1-0.1L3.4,0C3.5,0,3.4,0,3.4,0z"
                      />
                    </svg>
                  </a>
                </button>
                &nbsp;&nbsp;
                <button type="button" className="btn btn-circle btn-lg ">
                  {lang === "EN" ? (
                    isMobile ? (
                      <a
                        href={
                          "whatsapp://send?text=Use the following link to view" +
                          this.state.name +
                          "’s digital card: " +
                          this.state.pageurl +
                          " %0a" +
                          `%0a%0aPowered by Easy Card ${APIModel.WEBSITE}`
                        }
                        data-action="share/whatsapp/share"
                      >
                        <svg
                          className="svg-icon whatsapp-icon"
                          aria-hidden="true"
                          viewBox="0 0 4.5 4.5"
                        >
                          <path
                            class="st0"
                            d="M4.5,2.2c0,1.2-1,2.2-2.2,2.2c-0.4,0-0.8-0.1-1.1-0.3L0,4.5l0.4-1.2C0.2,3,0.1,2.6,0.1,2.2
		C0.1,1,1.1,0,2.3,0C3.5,0,4.5,1,4.5,2.2z M2.3,0.3c-1,0-1.9,0.8-1.9,1.8c0,0.4,0.1,0.8,0.4,1.1L0.6,4l0.7-0.2C1.6,3.9,1.9,4,2.3,4
		c1,0,1.9-0.8,1.9-1.8S3.3,0.3,2.3,0.3z M3.4,2.7c0,0,0,0-0.1-0.1C3.2,2.6,3,2.5,2.9,2.5c0,0-0.1,0-0.1,0c0,0.1-0.1,0.2-0.2,0.2
		c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.4-0.3C1.9,2.3,1.8,2.1,1.8,2.1c0-0.1,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1
		c0,0,0-0.1,0-0.1c0,0-0.1-0.3-0.2-0.4c0-0.1-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0s-0.1,0-0.1,0.1c0,0.1-0.2,0.2-0.2,0.4
		c0,0.3,0.2,0.5,0.2,0.6c0,0,0.4,0.6,0.9,0.8C2.9,3.3,2.9,3.2,3,3.2c0.1,0,0.3-0.1,0.4-0.3C3.4,2.8,3.4,2.7,3.4,2.7z"
                          />
                        </svg>
                      </a>
                    ) : (
                      <a
                        href={
                          "https://api.whatsapp.com/?text=Use the following link to view" +
                          this.state.name +
                          "’s digital card: " +
                          this.state.pageurl +
                          " %0a" +
                          `%0a%0aPowered by Easy Card ${APIModel.WEBSITE}`
                        }
                        data-action="share/whatsapp/share"
                      >
                        <svg
                          className="svg-icon whatsapp-icon"
                          aria-hidden="true"
                          viewBox="0 0 4.5 4.5"
                        >
                          <path
                            class="st0"
                            d="M4.5,2.2c0,1.2-1,2.2-2.2,2.2c-0.4,0-0.8-0.1-1.1-0.3L0,4.5l0.4-1.2C0.2,3,0.1,2.6,0.1,2.2
		C0.1,1,1.1,0,2.3,0C3.5,0,4.5,1,4.5,2.2z M2.3,0.3c-1,0-1.9,0.8-1.9,1.8c0,0.4,0.1,0.8,0.4,1.1L0.6,4l0.7-0.2C1.6,3.9,1.9,4,2.3,4
		c1,0,1.9-0.8,1.9-1.8S3.3,0.3,2.3,0.3z M3.4,2.7c0,0,0,0-0.1-0.1C3.2,2.6,3,2.5,2.9,2.5c0,0-0.1,0-0.1,0c0,0.1-0.1,0.2-0.2,0.2
		c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.4-0.3C1.9,2.3,1.8,2.1,1.8,2.1c0-0.1,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1
		c0,0,0-0.1,0-0.1c0,0-0.1-0.3-0.2-0.4c0-0.1-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0s-0.1,0-0.1,0.1c0,0.1-0.2,0.2-0.2,0.4
		c0,0.3,0.2,0.5,0.2,0.6c0,0,0.4,0.6,0.9,0.8C2.9,3.3,2.9,3.2,3,3.2c0.1,0,0.3-0.1,0.4-0.3C3.4,2.8,3.4,2.7,3.4,2.7z"
                          />
                        </svg>
                      </a>
                    )
                  ) : (
                    ""
                  )}

                  {lang === "HE" ? (
                    isMobile ? (
                      <a
                        href={
                          "whatsapp://send?text=לצפייה בכרטיס הביקור הדיגיטלי של " +
                          this.state.name +
                          this.state.pageurl +
                          " %0a" +
                          `%0a%0aPowered by Easy Card ${APIModel.WEBSITE}`
                        }
                        data-action="share/whatsapp/share"
                      >
                        <svg
                          className="svg-icon whatsapp-icon"
                          aria-hidden="true"
                          viewBox="0 0 4.5 4.5"
                        >
                          <path
                            class="st0"
                            d="M4.5,2.2c0,1.2-1,2.2-2.2,2.2c-0.4,0-0.8-0.1-1.1-0.3L0,4.5l0.4-1.2C0.2,3,0.1,2.6,0.1,2.2
		C0.1,1,1.1,0,2.3,0C3.5,0,4.5,1,4.5,2.2z M2.3,0.3c-1,0-1.9,0.8-1.9,1.8c0,0.4,0.1,0.8,0.4,1.1L0.6,4l0.7-0.2C1.6,3.9,1.9,4,2.3,4
		c1,0,1.9-0.8,1.9-1.8S3.3,0.3,2.3,0.3z M3.4,2.7c0,0,0,0-0.1-0.1C3.2,2.6,3,2.5,2.9,2.5c0,0-0.1,0-0.1,0c0,0.1-0.1,0.2-0.2,0.2
		c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.4-0.3C1.9,2.3,1.8,2.1,1.8,2.1c0-0.1,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1
		c0,0,0-0.1,0-0.1c0,0-0.1-0.3-0.2-0.4c0-0.1-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0s-0.1,0-0.1,0.1c0,0.1-0.2,0.2-0.2,0.4
		c0,0.3,0.2,0.5,0.2,0.6c0,0,0.4,0.6,0.9,0.8C2.9,3.3,2.9,3.2,3,3.2c0.1,0,0.3-0.1,0.4-0.3C3.4,2.8,3.4,2.7,3.4,2.7z"
                          />
                        </svg>
                      </a>
                    ) : (
                      <a
                        href={
                          "https://api.whatsapp.com/?text=לצפייה בכרטיס הביקור הדיגיטלי של " +
                          this.state.name +
                          this.state.pageurl +
                          " %0a" +
                          `%0a%0aPowered by Easy Card ${APIModel.WEBSITE}`
                        }
                        data-action="share/whatsapp/share"
                      >
                        <svg
                          className="svg-icon whatsapp-icon"
                          aria-hidden="true"
                          viewBox="0 0 4.5 4.5"
                        >
                          <path
                            class="st0"
                            d="M4.5,2.2c0,1.2-1,2.2-2.2,2.2c-0.4,0-0.8-0.1-1.1-0.3L0,4.5l0.4-1.2C0.2,3,0.1,2.6,0.1,2.2
		C0.1,1,1.1,0,2.3,0C3.5,0,4.5,1,4.5,2.2z M2.3,0.3c-1,0-1.9,0.8-1.9,1.8c0,0.4,0.1,0.8,0.4,1.1L0.6,4l0.7-0.2C1.6,3.9,1.9,4,2.3,4
		c1,0,1.9-0.8,1.9-1.8S3.3,0.3,2.3,0.3z M3.4,2.7c0,0,0,0-0.1-0.1C3.2,2.6,3,2.5,2.9,2.5c0,0-0.1,0-0.1,0c0,0.1-0.1,0.2-0.2,0.2
		c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.4-0.3C1.9,2.3,1.8,2.1,1.8,2.1c0-0.1,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1
		c0,0,0-0.1,0-0.1c0,0-0.1-0.3-0.2-0.4c0-0.1-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0s-0.1,0-0.1,0.1c0,0.1-0.2,0.2-0.2,0.4
		c0,0.3,0.2,0.5,0.2,0.6c0,0,0.4,0.6,0.9,0.8C2.9,3.3,2.9,3.2,3,3.2c0.1,0,0.3-0.1,0.4-0.3C3.4,2.8,3.4,2.7,3.4,2.7z"
                          />
                        </svg>
                      </a>
                    )
                  ) : (
                    ""
                  )}

                  {lang === "AR" ? (
                    isMobile ? (
                      <a
                        href={
                          "whatsapp://send?text=للتمعن في بطاقة العمل الالكترونيه ل " +
                          this.state.name +
                          this.state.pageurl +
                          " %0a" +
                          `%0a%0aPowered by Easy Card ${APIModel.WEBSITE}`
                        }
                        data-action="share/whatsapp/share"
                      >
                        <svg
                          className="svg-icon whatsapp-icon"
                          aria-hidden="true"
                          viewBox="0 0 4.5 4.5"
                        >
                          <path
                            class="st0"
                            d="M4.5,2.2c0,1.2-1,2.2-2.2,2.2c-0.4,0-0.8-0.1-1.1-0.3L0,4.5l0.4-1.2C0.2,3,0.1,2.6,0.1,2.2
		C0.1,1,1.1,0,2.3,0C3.5,0,4.5,1,4.5,2.2z M2.3,0.3c-1,0-1.9,0.8-1.9,1.8c0,0.4,0.1,0.8,0.4,1.1L0.6,4l0.7-0.2C1.6,3.9,1.9,4,2.3,4
		c1,0,1.9-0.8,1.9-1.8S3.3,0.3,2.3,0.3z M3.4,2.7c0,0,0,0-0.1-0.1C3.2,2.6,3,2.5,2.9,2.5c0,0-0.1,0-0.1,0c0,0.1-0.1,0.2-0.2,0.2
		c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.4-0.3C1.9,2.3,1.8,2.1,1.8,2.1c0-0.1,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1
		c0,0,0-0.1,0-0.1c0,0-0.1-0.3-0.2-0.4c0-0.1-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0s-0.1,0-0.1,0.1c0,0.1-0.2,0.2-0.2,0.4
		c0,0.3,0.2,0.5,0.2,0.6c0,0,0.4,0.6,0.9,0.8C2.9,3.3,2.9,3.2,3,3.2c0.1,0,0.3-0.1,0.4-0.3C3.4,2.8,3.4,2.7,3.4,2.7z"
                          />
                        </svg>
                      </a>
                    ) : (
                      <a
                        href={
                          "https://api.whatsapp.com/?text=للتمعن في بطاقة العمل الالكترونيه ل " +
                          this.state.name +
                          this.state.pageurl +
                          " %0a" +
                          `%0a%0aPowered by Easy Card ${APIModel.WEBSITE}`
                        }
                        data-action="share/whatsapp/share"
                      >
                        <svg
                          className="svg-icon whatsapp-icon"
                          aria-hidden="true"
                          viewBox="0 0 4.5 4.5"
                        >
                          <path
                            class="st0"
                            d="M4.5,2.2c0,1.2-1,2.2-2.2,2.2c-0.4,0-0.8-0.1-1.1-0.3L0,4.5l0.4-1.2C0.2,3,0.1,2.6,0.1,2.2
		C0.1,1,1.1,0,2.3,0C3.5,0,4.5,1,4.5,2.2z M2.3,0.3c-1,0-1.9,0.8-1.9,1.8c0,0.4,0.1,0.8,0.4,1.1L0.6,4l0.7-0.2C1.6,3.9,1.9,4,2.3,4
		c1,0,1.9-0.8,1.9-1.8S3.3,0.3,2.3,0.3z M3.4,2.7c0,0,0,0-0.1-0.1C3.2,2.6,3,2.5,2.9,2.5c0,0-0.1,0-0.1,0c0,0.1-0.1,0.2-0.2,0.2
		c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.4-0.3C1.9,2.3,1.8,2.1,1.8,2.1c0-0.1,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1
		c0,0,0-0.1,0-0.1c0,0-0.1-0.3-0.2-0.4c0-0.1-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0s-0.1,0-0.1,0.1c0,0.1-0.2,0.2-0.2,0.4
		c0,0.3,0.2,0.5,0.2,0.6c0,0,0.4,0.6,0.9,0.8C2.9,3.3,2.9,3.2,3,3.2c0.1,0,0.3-0.1,0.4-0.3C3.4,2.8,3.4,2.7,3.4,2.7z"
                          />
                        </svg>
                      </a>
                    )
                  ) : (
                    ""
                  )}
                </button>
                &nbsp;&nbsp;
                <button type="button" className="btn btn-circle btn-lg ">
                  {lang === "EN" ? (
                    isIOS ? (
                      <a
                        href={
                          "sms:?body=Use the following link to view " +
                          this.state.name +
                          "'s digital card:%0a" +
                          this.state.pageurl +
                          `%0a%0aPowered by Easy Card ${APIModel.WEBSITE}`
                        }
                      >
                        <svg
                          className="svg-icon"
                          aria-hidden="true"
                          viewBox="0 0 4.5 4.5"
                        >
                          <path
                            class="st0"
                            d="M2.2,0.3C1,0.3,0,1.1,0,2.1c0,0.4,0.2,0.8,0.5,1.1C0.4,3.7,0,4.1,0,4.1c0,0,0,0,0,0.1s0,0,0.1,0
c0.6,0,1-0.3,1.2-0.4c0.3,0.1,0.6,0.2,0.9,0.2c1.2,0,2.2-0.8,2.2-1.8S3.5,0.3,2.2,0.3z M1.1,2.4C1,2.4,0.9,2.3,0.9,2.1
C0.9,2,1,1.8,1.1,1.8c0.2,0,0.3,0.1,0.3,0.3S1.3,2.4,1.1,2.4z M2.2,2.4C2.1,2.4,2,2.3,2,2.1C2,2,2.1,1.8,2.2,1.8S2.5,2,2.5,2.1
S2.4,2.4,2.2,2.4z M3.4,2.4c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3S3.5,2.4,3.4,2.4z"
                          />
                        </svg>
                      </a>
                    ) : (
                      <a
                        href={
                          "sms:?body=Use the following link to view " +
                          this.state.name +
                          "'s digital card:%0a" +
                          this.state.pageurl +
                          `%0a%0aPowered by Easy Card ${APIModel.WEBSITE}`
                        }
                      >
                        <svg
                          className="svg-icon"
                          aria-hidden="true"
                          viewBox="0 0 4.5 4.5"
                        >
                          <path
                            class="st0"
                            d="M2.2,0.3C1,0.3,0,1.1,0,2.1c0,0.4,0.2,0.8,0.5,1.1C0.4,3.7,0,4.1,0,4.1c0,0,0,0,0,0.1s0,0,0.1,0
	c0.6,0,1-0.3,1.2-0.4c0.3,0.1,0.6,0.2,0.9,0.2c1.2,0,2.2-0.8,2.2-1.8S3.5,0.3,2.2,0.3z M1.1,2.4C1,2.4,0.9,2.3,0.9,2.1
	C0.9,2,1,1.8,1.1,1.8c0.2,0,0.3,0.1,0.3,0.3S1.3,2.4,1.1,2.4z M2.2,2.4C2.1,2.4,2,2.3,2,2.1C2,2,2.1,1.8,2.2,1.8S2.5,2,2.5,2.1
	S2.4,2.4,2.2,2.4z M3.4,2.4c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3S3.5,2.4,3.4,2.4z"
                          />
                        </svg>
                      </a>
                    )
                  ) : (
                    ""
                  )}

                  {lang === "HE" ? (
                    isIOS ? (
                      <a
                        href={
                          "sms:?body=לצפייה בכרטיס הביקור הדיגיטלי של " +
                          this.state.name +
                          "'s digital card:%0a" +
                          this.state.pageurl +
                          `%0a%0aPowered by Easy Card ${APIModel.WEBSITE}`
                        }
                      >
                        <svg
                          className="svg-icon"
                          aria-hidden="true"
                          viewBox="0 0 4.5 4.5"
                        >
                          <path
                            class="st0"
                            d="M2.2,0.3C1,0.3,0,1.1,0,2.1c0,0.4,0.2,0.8,0.5,1.1C0.4,3.7,0,4.1,0,4.1c0,0,0,0,0,0.1s0,0,0.1,0
	c0.6,0,1-0.3,1.2-0.4c0.3,0.1,0.6,0.2,0.9,0.2c1.2,0,2.2-0.8,2.2-1.8S3.5,0.3,2.2,0.3z M1.1,2.4C1,2.4,0.9,2.3,0.9,2.1
	C0.9,2,1,1.8,1.1,1.8c0.2,0,0.3,0.1,0.3,0.3S1.3,2.4,1.1,2.4z M2.2,2.4C2.1,2.4,2,2.3,2,2.1C2,2,2.1,1.8,2.2,1.8S2.5,2,2.5,2.1
	S2.4,2.4,2.2,2.4z M3.4,2.4c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3S3.5,2.4,3.4,2.4z"
                          />
                        </svg>
                      </a>
                    ) : (
                      <a
                        href={
                          "sms:?body=לצפייה בכרטיס הביקור הדיגיטלי של " +
                          this.state.name +
                          "'s digital card:%0a" +
                          this.state.pageurl +
                          `%0a%0aPowered by Easy Card ${APIModel.WEBSITE}`
                        }
                      >
                        <svg
                          className="svg-icon"
                          aria-hidden="true"
                          viewBox="0 0 4.5 4.5"
                        >
                          <path
                            class="st0"
                            d="M2.2,0.3C1,0.3,0,1.1,0,2.1c0,0.4,0.2,0.8,0.5,1.1C0.4,3.7,0,4.1,0,4.1c0,0,0,0,0,0.1s0,0,0.1,0
		c0.6,0,1-0.3,1.2-0.4c0.3,0.1,0.6,0.2,0.9,0.2c1.2,0,2.2-0.8,2.2-1.8S3.5,0.3,2.2,0.3z M1.1,2.4C1,2.4,0.9,2.3,0.9,2.1
		C0.9,2,1,1.8,1.1,1.8c0.2,0,0.3,0.1,0.3,0.3S1.3,2.4,1.1,2.4z M2.2,2.4C2.1,2.4,2,2.3,2,2.1C2,2,2.1,1.8,2.2,1.8S2.5,2,2.5,2.1
		S2.4,2.4,2.2,2.4z M3.4,2.4c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3S3.5,2.4,3.4,2.4z"
                          />
                        </svg>
                      </a>
                    )
                  ) : (
                    ""
                  )}

                  {lang === "AR" ? (
                    isIOS ? (
                      <a
                        href={
                          "sms:?body=للتمعن في بطاقة العمل الالكترونيه ل " +
                          this.state.name +
                          "'s digital card:%0a" +
                          this.state.pageurl +
                          `%0a%0aPowered by Easy Card ${APIModel.WEBSITE}`
                        }
                      >
                        <svg
                          className="svg-icon"
                          aria-hidden="true"
                          viewBox="0 0 4.5 4.5"
                        >
                          <path
                            class="st0"
                            d="M2.2,0.3C1,0.3,0,1.1,0,2.1c0,0.4,0.2,0.8,0.5,1.1C0.4,3.7,0,4.1,0,4.1c0,0,0,0,0,0.1s0,0,0.1,0
c0.6,0,1-0.3,1.2-0.4c0.3,0.1,0.6,0.2,0.9,0.2c1.2,0,2.2-0.8,2.2-1.8S3.5,0.3,2.2,0.3z M1.1,2.4C1,2.4,0.9,2.3,0.9,2.1
C0.9,2,1,1.8,1.1,1.8c0.2,0,0.3,0.1,0.3,0.3S1.3,2.4,1.1,2.4z M2.2,2.4C2.1,2.4,2,2.3,2,2.1C2,2,2.1,1.8,2.2,1.8S2.5,2,2.5,2.1
S2.4,2.4,2.2,2.4z M3.4,2.4c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3S3.5,2.4,3.4,2.4z"
                          />
                        </svg>
                      </a>
                    ) : (
                      <a
                        href={
                          "sms:?body=للتمعن في بطاقة العمل الالكترونيه ل " +
                          this.state.name +
                          "'s digital card:%0a" +
                          this.state.pageurl +
                          `%0a%0aPowered by Easy Card ${APIModel.WEBSITE}`
                        }
                      >
                        <svg
                          className="svg-icon"
                          aria-hidden="true"
                          viewBox="0 0 4.5 4.5"
                        >
                          <path
                            class="st0"
                            d="M2.2,0.3C1,0.3,0,1.1,0,2.1c0,0.4,0.2,0.8,0.5,1.1C0.4,3.7,0,4.1,0,4.1c0,0,0,0,0,0.1s0,0,0.1,0
	c0.6,0,1-0.3,1.2-0.4c0.3,0.1,0.6,0.2,0.9,0.2c1.2,0,2.2-0.8,2.2-1.8S3.5,0.3,2.2,0.3z M1.1,2.4C1,2.4,0.9,2.3,0.9,2.1
	C0.9,2,1,1.8,1.1,1.8c0.2,0,0.3,0.1,0.3,0.3S1.3,2.4,1.1,2.4z M2.2,2.4C2.1,2.4,2,2.3,2,2.1C2,2,2.1,1.8,2.2,1.8S2.5,2,2.5,2.1
	S2.4,2.4,2.2,2.4z M3.4,2.4c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3S3.5,2.4,3.4,2.4z"
                          />
                        </svg>
                      </a>
                    )
                  ) : (
                    ""
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>

        <footer>
          <div className="container h-100 footer-wrapper">
            <div className="row h-100 footer-wrapper justify-content-center align-items-center">
              <a href={APIModel.WEBSITE}>
                <img src="/assets/images/footer_create_card.jpg"></img>
              </a>
            </div>
          </div>
        </footer>
        {/* <script type="text/javascript" src="js/main.js"></script> */}
      </div>
    );
  }

  render() {
    if (this.state.noCard === true) {
      document.body.className = document.body.className.replace("bgmain", "");
      return (
        <div className="wrapper">
          <div className="container">
            <nav className="navbar navbar-default">
              <div className="container">
                <div className="navbar-header page-scroll">
                  <button
                    type="button"
                    className="navbar-toggle"
                    data-toggle="collapse"
                    data-target="#bs-example-navbar-collapse-1"
                    aria-expanded="false"
                  >
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>
                  <a
                    className="navbar-brand page-scroll"
                    href="https://dev.easy-card.co/#/"
                  >
                    <img
                      src="/assets/images/easy-card.png"
                      width="105"
                      height="35"
                      alt="iLand"
                    />
                  </a>
                </div>
                <div
                  className="collapse navbar-collapse navbar-right"
                  id="bs-example-navbar-collapse-1"
                >
                  <ul className="nav navbar-nav">
                    <li>
                      <a
                        className="page-scroll"
                        href="https://dev.easy-card.co/#/"
                        style={{ color: "#777" }}
                      >
                        Home
                      </a>
                    </li>

                    <li>
                      <a
                        className="page-scroll"
                        href="https://portal.easy-card.co/"
                        style={{ color: "#777" }}
                      >
                        Sign-In
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>

          <div className="container">
            <div className="row" style={{ "margin-top": "6%" }}>
              <div className="col-md-12 text-center">
                <img src="/assets/images/404.png" alt="" />
              </div>
            </div>
            <div className="row">
              <div
                className="col-md-12 text-center"
                style={{
                  "margin-top": "20px",
                  "font-weight": "bolder",
                  color: "#B55D15",
                }}
              >
                <a href={APIModel.WEBSITE}>
                  <h1>create your own easy card now!</h1>
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (!this.state.process) {
      if (!isMobile) {
        if (this.state.noCard == true) {
          return <div>No Card Available</div>;
        } else {
          if (this.state.browser == true) {
            return (
              <div id="desktop">
                <div
                  className="bgmain"
                  style={{
                    backgroundImage: "/assets/images/bg.jpg",
                  }}
                >
                  <div className="displaydesktop">
                    <div className="center-div">
                      <img
                        id="the-frame"
                        src="/assets/images/mobile.png"
                        alt=""
                      />
                    </div>
                    <div className="center-div2" id="iframe-container">
                      {this.renderMobileCard()}
                    </div>
                  </div>
                  {this.desktopScripts()}
                </div>
              </div>
            );
          } else {
            return (
              <div className="wrapper">
                <div className="container">
                  <nav className="navbar navbar-default">
                    <div className="container">
                      <div className="navbar-header page-scroll">
                        <button
                          type="button"
                          className="navbar-toggle"
                          data-toggle="collapse"
                          data-target="#bs-example-navbar-collapse-1"
                          aria-expanded="false"
                        >
                          <span className="sr-only">Toggle navigation</span>
                          <span className="icon-bar"></span>
                          <span className="icon-bar"></span>
                          <span className="icon-bar"></span>
                        </button>
                        <a
                          className="navbar-brand page-scroll"
                          href="https://dev.easy-card.co/#/"
                        >
                          <img
                            src="/assets/images/easy-card.png"
                            width="105"
                            height="35"
                            alt="iLand"
                          />
                        </a>
                      </div>
                      <div
                        className="collapse navbar-collapse navbar-right"
                        id="bs-example-navbar-collapse-1"
                      >
                        <ul className="nav navbar-nav">
                          <li>
                            <a
                              className="page-scroll"
                              href="https://dev.easy-card.co/#/"
                              style={{
                                color: "#777",
                              }}
                            >
                              Home
                            </a>
                          </li>

                          <li>
                            <a
                              className="page-scroll"
                              href="https://portal.easy-card.co/"
                              style={{
                                color: "#777",
                              }}
                            >
                              Sign-In
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </nav>
                </div>

                <div className="container">
                  <div className="row" style={{ "margin-top": "6%" }}>
                    <div className="col-md-12 text-center">
                      <img src="/assets/images/404.png" alt="" />
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-md-12 text-center"
                      style={{
                        "margin-top": "20px",
                        "font-weight": "bolder",
                        color: "#B55D15",
                      }}
                    >
                      <a href="https://dev.easy-card.co/">
                        <h1>create your own easy card now!</h1>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        }
      } else {
        if (this.state.noCard == true) {
          return <div>No Card Available</div>;
        }
        if (this.state.browser == true && this.state.login == true) {
          return this.renderMobileCard();
        } else {
          return (
            <div className="wrapper">
              <div className="container">
                <nav className="navbar navbar-default">
                  <div className="container">
                    <div className="navbar-header page-scroll">
                      <button
                        type="button"
                        className="navbar-toggle"
                        data-toggle="collapse"
                        data-target="#bs-example-navbar-collapse-1"
                        aria-expanded="false"
                      >
                        <span className="sr-only">Toggle navigation</span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                      </button>
                      <a
                        className="navbar-brand page-scroll"
                        href="https://dev.easy-card.co/#/"
                      >
                        <img
                          src="/assets/images/easy-card.png"
                          width="105"
                          height="35"
                          alt="iLand"
                        />
                      </a>
                    </div>
                    <div
                      className="collapse navbar-collapse navbar-right"
                      id="bs-example-navbar-collapse-1"
                    >
                      <ul className="nav navbar-nav">
                        <li>
                          <a
                            className="page-scroll"
                            href="https://dev.easy-card.co/#/"
                            style={{
                              color: "#777",
                            }}
                          >
                            Home
                          </a>
                        </li>

                        <li>
                          <a
                            className="page-scroll"
                            href="https://portal.easy-card.co/"
                            style={{
                              color: "#777",
                            }}
                          >
                            Sign-In
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </nav>
              </div>

              <div className="container">
                <div className="row" style={{ "margin-top": "6%" }}>
                  <div className="col-md-12 text-center">
                    <img src="/assets/images/404.png" alt="" />
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-md-12 text-center"
                    style={{
                      "margin-top": "20px",
                      "font-weight": "bolder",
                      color: "#B55D15",
                    }}
                  >
                    <a href="https://dev.easy-card.co/">
                      <h1>create your own easy card now!</h1>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      }
    } else {
      if (this.state.login == false || this.state.browser == false) {
        return (
          <div className="wrapper">
            <div className="container">
              <nav className="navbar navbar-default">
                <div className="container">
                  <div className="navbar-header page-scroll">
                    <button
                      type="button"
                      className="navbar-toggle"
                      data-toggle="collapse"
                      data-target="#bs-example-navbar-collapse-1"
                      aria-expanded="false"
                    >
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                    </button>
                    <a
                      className="navbar-brand page-scroll"
                      href="https://dev.easy-card.co/#/"
                    >
                      <img
                        src="/assets/images/easy-card.png"
                        width="105"
                        height="35"
                        alt="iLand"
                      />
                    </a>
                  </div>
                  <div
                    className="collapse navbar-collapse navbar-right"
                    id="bs-example-navbar-collapse-1"
                  >
                    <ul className="nav navbar-nav">
                      <li>
                        <a
                          className="page-scroll"
                          href={APIModel.WEBSITE}
                          style={{ color: "#777" }}
                        >
                          Home
                        </a>
                      </li>

                      <li>
                        <a
                          className="page-scroll"
                          href={APIModel.PORTAL}
                          style={{ color: "#777" }}
                        >
                          Sign-In
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>

            <div className="container">
              <div className="row" style={{ "margin-top": "6%" }}>
                <div className="col-md-12 text-center">
                  <img src="/assets/images/404.png" alt="" />
                </div>
              </div>
              <div className="row">
                <div
                  className="col-md-12 text-center"
                  style={{
                    "margin-top": "20px",
                    "font-weight": "bolder",
                    color: "#B55D15",
                  }}
                >
                  <a href={APIModel.PORTAL}>
                    <h1>create your own easy card now!</h1>
                  </a>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return null;
      }
    }
    // else {
    //     return (
    //       <div className="wrapper">
    //         <div className="container">
    //           <nav className="navbar navbar-default">
    //             <div className="container">
    //               <div className="navbar-header page-scroll">
    //                 <button
    //                   type="button"
    //                   className="navbar-toggle"
    //                   data-toggle="collapse"
    //                   data-target="#bs-example-navbar-collapse-1"
    //                   aria-expanded="false"
    //                 >
    //                   <span className="sr-only">Toggle navigation</span>
    //                   <span className="icon-bar"></span>
    //                   <span className="icon-bar"></span>
    //                   <span className="icon-bar"></span>
    //                 </button>
    //                 <a
    //                   className="navbar-brand page-scroll"
    //                   href="https://dev.easy-card.co/#/"
    //                 >
    //                   <img
    //                     src="/assets/images/easy-card.png"
    //                     width="105"
    //                     height="35"
    //                     alt="iLand"
    //                   />
    //                 </a>
    //               </div>
    //               <div
    //                 className="collapse navbar-collapse navbar-right"
    //                 id="bs-example-navbar-collapse-1"
    //               >
    //                 <ul className="nav navbar-nav">
    //                   <li>
    //                     <a
    //                       className="page-scroll"
    //                       href="https://dev.easy-card.co/#/"
    //                       style={{ color: "#777" }}
    //                     >
    //                       Home
    //                     </a>
    //                   </li>

    //                   <li>
    //                     <a
    //                       className="page-scroll"
    //                       href="https://portal.easy-card.co/"
    //                       style={{ color: "#777" }}
    //                     >
    //                       Sign-In
    //                     </a>
    //                   </li>
    //                 </ul>
    //               </div>
    //             </div>
    //           </nav>
    //         </div>

    //         <div className="container">
    //           <div className="row" style={{ "margin-top": "6%" }}>
    //             <div className="col-md-12 text-center">
    //               <img src="/assets/images/404.png" alt="" />
    //             </div>
    //           </div>
    //           <div className="row">
    //             <div
    //               className="col-md-12 text-center"
    //               style={{
    //                 "margin-top": "20px",
    //                 "font-weight": "bolder",
    //                 color: "#B55D15"
    //               }}
    //             >
    //               <a href="https://dev.easy-card.co/">
    //                 <h1>create your own easy card now!</h1>
    //               </a>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     );
    //   }
    // }
  }
}

// const mapStateToProps = state => {
//     return {
//         user: state.AuthReducer.user
//     };
// };

const mapDispatchToProps = () => {
  return {
    get: (slug) => actionCreater.getLogedInSlug(slug),
  };
};

export default connect(null, mapDispatchToProps)(LogedInUserCard);
